import {FC} from "react";
import classes from "./Header.module.css";
import vistageLogo from "../../assets/images/vistage-logo.png";

const Header: FC = () => {
    return (
        <div className={classes.header}>
            <div className={`${classes["heading-and-logo-row"]} font-weight-bold`}>
                <div className={classes.heading}>
                    <p>MEMBERSHIP APPLICATION</p>
                </div>
                <div className={classes.logo}>
                    <img src={vistageLogo} alt="Vistage Logo"/>
                </div>
            </div>
            <div className={classes["vistage-mission-and-values-row"]}>
                <div className={classes["vistage-mission"]}>
                    <p className="font-weight-bold">Our mission</p>
                    <p>Improving the effectiveness and enhancing the lives of CEOs, business owners and key executives of small and medium-sized businesses.</p>
                </div>
                <div className={classes["vistage-values"]}>
                    <p className="font-weight-bold">Our values</p>
                    <p>Trust, Caring, Challenge, Growth</p>
                </div>
            </div>
        </div>
    )
}

export default Header;
