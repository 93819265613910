import {FC} from "react";
import classes from "./Step.module.css"

const Step: FC<{step: number, text: string, isDisabled: boolean}> = ({step, text, isDisabled}) => {
    return (
        <div className={`${classes.step} col-md-4 col-sm-4 col-12 ${ isDisabled ? classes['step-disabled'] : ''}`}>
            <div className={`${classes['step-link']}`}>
                <span className={classes['step-circle']}>{step}</span>
                <span className={classes['step-label']}>{text}</span>
            </div>
        </div>
    );
}

export default Step;
