import {FC, MouseEventHandler} from "react";
import classes from "../SubmitButton/SubmitButton.module.css";

const SubmitButton: FC<{text: string, onClickEventHandler: MouseEventHandler}> = ({text, onClickEventHandler}) => {
    return (
        <button className={classes['submit-button']} onClick={onClickEventHandler}>
            {text}
        </button>
    )
}
export default SubmitButton;
