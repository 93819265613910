import React, {FC} from "react";
import sampleCheck from "../../assets/images/sample-check.png";

const EFTHelpText: FC = () => {
    return (
        <div className={"row margin-top-20"}>
            <div className={"col-md-3 col-sm-6 col-sm-12"}></div>
            <div className={"col-md-9"}>
                <div>Help with Routing and Account Number</div>
                <div className={"margin-top-10"}>Please refer to the sample check below to identify the placement of your bank's nine-digit routing number and your bank account number.</div>
                <img src={sampleCheck} alt={"check"} className={"sample-check-image margin-top-20"} />
            </div>
        </div>
    );
}
export default EFTHelpText
