import React, {FC} from "react";
import classes from "./MessageBox.module.css";
import Modal from "../Modal/Modal";
type MessageBoxProps = {
    title: string;
    message: string
    buttonClickHandler: any;
}
const MessageBox: FC<MessageBoxProps> = ({title, message, buttonClickHandler}) => {
    return (
        <Modal>
            <div className={classes['message-box-container']}>
                <div className={classes['message-box-header']}>
                    <div className={classes['message-box-row']}>
                        <h5>{title}</h5>
                    </div>
                </div>
                <div className={classes['message-box-content']}>
                    <div className={classes['message-box-row align-items-center']}>
                        <div dangerouslySetInnerHTML={{__html: message}}></div>
                    </div>
                </div>
                <div className={classes['message-box-footer']}>
                    <button className={classes['message-box-btn']} onClick={buttonClickHandler}>Ok</button>
                </div>
            </div>
        </Modal>
    );
}
export default MessageBox;
