import React, {ChangeEvent, FC, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useHttp from "../../hooks/use-http";
import useInput from "../../hooks/use-input";
import AppContext from "../../store/app-context";
import MembershipApplicationContext from "../../store/membership-application-context";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import Header from "../../components/Header/Header";
import StepNavigation from "../../components/StepNavigation/StepNavigation";
import Card from "../../components/Card/Card";
import NavigationButton from "../../components/NavigationButton/NavigationButton";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/Input/Input";
import {useForceNavigate} from "../../guards/TokenValidationGuard";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Select from "../../components/Select/Select";
import {Industry} from "../../models/Industry";
import {IndustryDetail} from "../../models/IndustryDetail";
import {Country} from "../../models/Country";
import {State} from "../../models/State";
import {MembershipApplication} from "../../models/MembershipApplication";
import {Contact} from "../../models/Contact";
import {
    annualRevenueValues,
    countriesEndpoint,
    errorLoadingDataMessage,
    errorUpdatingApplication,
    errorUpdatingApplicationWithIdAndToken,
    errorUpdatingSalesforceContact,
    failedToLoadCountryData,
    failedToLoadIndustryData,
    failedToLoadStatesData,
    genderValues,
    industryEndpoint,
    isNotEmpty,
    loadingMemberInformation,
    numberOfEmployeesValues,
    generateTeamsNotificationPayload,
    prefixValues,
    sanitizeValue,
    generateApplicationErrorEmail,
    statesEndpoint,
    typeOfOrganizationValues,
    updateMembershipApplicationEndpoint,
    updateSalesforceContactEndpoint,
    updatingApplication,
    writeToLogsEndpoint,
    writeToLogs
} from "../../utils/Constants";

const MemberInformation: FC = () => {
    const {token} = useParams<{ token: string }>();
    const {isLoading, sendRequest} = useHttp();
    const navigate = useNavigate();
    const forceNavigate = useForceNavigate();
    const {membershipApplication, updateMembershipApplication} = useContext(MembershipApplicationContext);
    const currentYear = new Date().getFullYear();
    const yearRange = `${currentYear - 100}:${currentYear}`;
    const [formHasErrors, setFormHasErrors] = useState<boolean>(false);
    const [formError, setFormError] = useState('');
    const [showDataLoadError, setShowDataLoadError] = useState(false);

    useEffect(() => {
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            token: token,
            body: writeToLogs(loadingMemberInformation(token))
        }, ()=>{}, () => {})
    }, [sendRequest, token]);
    const {config} = useContext(AppContext);

    const [prefixValue, setPrefixValue] = useState(membershipApplication?.person.prefix || '');
    const prefixChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setPrefixValue(e.target.value);
    }
    const firstNameValue = membershipApplication?.person.first || '';
    const lastNameValue = membershipApplication?.person.last || '';
    const emailValue = membershipApplication?.person.email || '';

    const [middleInitialValue, setMiddleInitialValue] = useState(membershipApplication?.person.middleInitial || '');
    const middleInitialChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setMiddleInitialValue(event.target.value);
    }

    const suffixDefaultValue = membershipApplication?.person.suffix || '';
    const {
        value: suffixValue,
        valueChangeHandler: suffixChangeHandler
    } = useInput(suffixDefaultValue, () => {});

    const preferredNameDefaultValue = membershipApplication?.person.preferredName || '';
    const {
        value: preferredNameValue,
        valueChangeHandler: preferredNameChangeHandler
    } = useInput(preferredNameDefaultValue, () => {});

    const jobTitleDefaultValue = membershipApplication?.person.jobTitle || '';
    const isJobTitleValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: jobTitleValue,
        hasError: jobTitleValueHasError,
        valueChangeHandler: jobTitleChangeHandler
    } = useInput(jobTitleDefaultValue, isJobTitleValueValid);

    const workPhoneDefaultValue = membershipApplication?.person.workPhone || '';
    const isWorkPhoneValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: workPhoneValue,
        hasError: workPhoneValueHasError,
        valueChangeHandler: workPhoneChangeHandler
    } = useInput(workPhoneDefaultValue, isWorkPhoneValueValid);

    const [mobilePhoneValue, setMobilePhoneValue] = useState(membershipApplication?.person.mobilePhone || '');
    const mobilePhoneChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setMobilePhoneValue(event.target.value);
    }

    const [genderValue, setGenderValue] = useState(membershipApplication?.person.gender || '');
    const genderValueChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        setGenderValue(event.target.value);
    }
    const defaultGenderOtherValue = membershipApplication?.person.genderOther || '';
    const isGenderOtherValueValid = (value: string) => {
        return genderValue !== 'Other' ? true : isNotEmpty(value);
    }
    const {
        value: genderOtherValue,
        hasError: genderOtherValueHasError,
        valueChangeHandler: genderOtherValueChangeHandler
    } = useInput(defaultGenderOtherValue, isGenderOtherValueValid);

    const defaultBirthDateValue = membershipApplication?.person.birthday
        ? new Date(moment(membershipApplication?.person.birthday).format('YYYY/MM/DD')) : undefined;
    const [birthdayValue, setBirthdayValue] = useState<Date|undefined>(defaultBirthDateValue);
    const birthdayValueChangeHandler = (value: Date) => {
        setBirthdayValue(value);
    }

    const defaultCompanyNameValue = membershipApplication?.company.name || '';
    const isCompanyNameValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: companyNameValue,
        hasError: companyNameValueHasError,
        valueChangeHandler: companyNameChangeHandler
    } = useInput(defaultCompanyNameValue, isCompanyNameValueValid);

    const defaultBusinessDescriptionValue = membershipApplication?.company.businessDescription || '';
    const [businessDescriptionValue, setBusinessDescriptionValue] = useState(defaultBusinessDescriptionValue);
    const businessDescriptionValueChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setBusinessDescriptionValue(event.target.value);
    }

    const [typeOfOrgValue, setTypeOfOrgValue] = useState(membershipApplication?.company.typeOfOrg || '');
    const typeOfOrgValueChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setTypeOfOrgValue(e.target.value);
    }

    const [annualRevenueValue, setAnnualRevenueValue] = useState(membershipApplication?.company.annualRevenue || '');
    const annualRevenueValueChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setAnnualRevenueValue(e.target.value);
    }

    const [numberOfEmployeesValue, setNumberOfEmployeesValue] = useState(membershipApplication?.company.numberOfEmployee || '');
    const numberOfEmployeesValueChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setNumberOfEmployeesValue(e.target.value);
    }

    const [industries, setIndustries] = useState([]);
    const naicsValue: string|undefined = membershipApplication?.company.industryNAICS;
    const [selectedIndustry, setSelectedIndustry] = useState<number | undefined>(undefined);
    const [industryDetails, setIndustryDetails] = useState<{ value: number; display: string }[]>([]);
    const [selectedIndustryDetail, setSelectedIndustryDetail] = useState<number | undefined>(naicsValue ? +naicsValue : undefined);
    useEffect(() => {
        sendRequest({
            url: industryEndpoint,
            method: 'GET',
            token: token,
        }, (response: any) => {
            setIndustries(response.industries);
            const foundIndustry = response.industries.find((industry: Industry) =>
                industry.industryDetail.some((detail: IndustryDetail) => {
                    return naicsValue ? detail.code === +naicsValue : false;
                })
            );
            if (foundIndustry) {
                setSelectedIndustry(foundIndustry.code);
                setIndustryDetails(
                    foundIndustry.industryDetail.map((detail: IndustryDetail) => ({
                        value: detail.code,
                        display: detail.description,
                    }))
                );
            }
        }, (error: any) => {
            sendRequest({
                url: writeToLogsEndpoint,
                method: 'POST',
                token: token,
                body: writeToLogs(failedToLoadIndustryData(token), undefined, error)
            }, ()=>{}, () => {})
            setFormError(errorLoadingDataMessage);
            setShowDataLoadError(true);
        })
    }, [sendRequest, token, naicsValue]);

    const industryOptions = industries.map((industry: Industry) => ({
        value: industry.code,
        display: industry.description,
    }));

    const handleIndustryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCode = Number(event.target.value);
        setSelectedIndustry(selectedCode);

        const selectedIndustryObj = industries.find(
            (ind: Industry) => ind.code === selectedCode
        ) as Industry | undefined;

        if (selectedIndustryObj) {
            setIndustryDetails(
                selectedIndustryObj.industryDetail?.map((detail: IndustryDetail) => ({
                    value: detail.code,
                    display: detail.description,
                })) || []
            );
        } else {
            setIndustryDetails([]);
        }
        setSelectedIndustryDetail(undefined);
    };

    const handleIndustryDetailChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedIndustryDetail(Number(event.target.value));
    }

    const [countries, setCountries] = useState<Country[]>([]);
    const [states, setStates] = useState<State[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string>(membershipApplication?.company.country || 'US');
    const [selectedState, setSelectedState] = useState<string>(membershipApplication?.company.state || '');
    useEffect(() => {
        sendRequest({
            url: countriesEndpoint,
            method: 'GET',
            token: token,
        }, (response: any) => {
            setCountries(response.countries);
        }, (error: any) => {
            sendRequest({
                url: writeToLogsEndpoint,
                method: 'POST',
                token: token,
                body: writeToLogs(failedToLoadCountryData(token), undefined, error)
            }, ()=>{}, () => {})
            setFormError(errorLoadingDataMessage);
            setShowDataLoadError(true);
        });
    }, [sendRequest, token]);
    const countriesOptions = countries.map((country: Country) => ({
        value: country.countryCode,
        display: country.name,
    }));
    const countryValueChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCountry(event.target.value);
    }

    useEffect(() => {
        if (selectedCountry === 'US' || selectedCountry === 'CA') {
            sendRequest({
                url: statesEndpoint(selectedCountry),
                method: 'GET',
                token: token,
            }, (response: any) => {
                setStates(response.states);
                const foundState = response.states.find((state: State) => {
                    return state.stateCode === membershipApplication?.company.state;
                });
                if (foundState) {
                    setSelectedState(foundState.stateCode);
                }
            }, (error: any) => {
                sendRequest({
                    url: writeToLogsEndpoint,
                    method: 'POST',
                    token: token,
                    body: writeToLogs(failedToLoadStatesData(token), undefined, error)
                }, ()=>{}, () => {})
                setFormError(errorLoadingDataMessage);
                setShowDataLoadError(true);
            })
        } else {
            setStates([]);
        }
    }, [membershipApplication?.company.state, selectedCountry, sendRequest, token]);
    const statesOptions = states.map((state: State) => ({
        value: state.stateCode,
        display: state.name
    }));
    const stateValueChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedState(event.target.value);
    }

    const defaultAddress1Value = membershipApplication?.company.address1 || '';
    const isAddress1Valid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: address1Value,
        hasError: address1ValueHasError,
        valueChangeHandler: address1ValueChangeHandler
    } = useInput(defaultAddress1Value, isAddress1Valid);

    const [address2Value, setAddress2Value] = useState(membershipApplication?.company.address2 || '');
    const address2ValueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setAddress2Value(event.target.value);
    }

    const defaultCityValue = membershipApplication?.company.city || '';
    const isCityValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: cityValue,
        hasError: cityValueHasError,
        valueChangeHandler: cityValueChangeHandler
    } = useInput(defaultCityValue, isCityValueValid);

    const zipDefaultValue = membershipApplication?.company.zip || '';
    const isZipValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: zipValue,
        hasError: zipValueHasError,
        valueChangeHandler: zipValueChangeHandler
    } = useInput(zipDefaultValue, isZipValueValid);

    const [websiteValue, setWebsiteValue] = useState(membershipApplication?.company.website || '');
    const websiteValueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setWebsiteValue(event.target.value);
    }

    useEffect(() => {
        setTypeOfOrgValue(membershipApplication?.company.typeOfOrg || '');
        setAnnualRevenueValue(membershipApplication?.company.annualRevenue || '');
        setNumberOfEmployeesValue(membershipApplication?.company.numberOfEmployee || '');
        setSelectedIndustryDetail(membershipApplication?.company.industryNAICS ? +membershipApplication?.company.industryNAICS : undefined)
        setBusinessDescriptionValue(membershipApplication?.company.businessDescription || '');
    }, [membershipApplication]);


    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    const goBack= () => {
        // TODO: handle state update
        forceNavigate(`/${token}/membership-commitments`);
    }
    const saveApplication = () => {
        setFormError('');
        if (jobTitleValueHasError || workPhoneValueHasError || companyNameValueHasError
            || !isNotEmpty(annualRevenueValue) || !isNotEmpty(typeOfOrgValue) || !isNotEmpty(numberOfEmployeesValue)
            || !selectedIndustry || !selectedIndustryDetail
            || address1ValueHasError || cityValueHasError || zipValueHasError || !selectedState || !selectedIndustry) {
            setFormHasErrors(true);
            setFormError('*Please complete the required fields.');
            scrollToTop();
            return;
        }
        const membershipApplicationCopy: MembershipApplication = {
            ...membershipApplication!,
            stepTwoSaved: true,
            person: {
                ...membershipApplication!.person,
                prefix: sanitizeValue(prefixValue),
                middleInitial: sanitizeValue(middleInitialValue),
                suffix: sanitizeValue(suffixValue),
                preferredName: sanitizeValue(preferredNameValue),
                jobTitle: jobTitleValue,
                workPhone: workPhoneValue,
                mobilePhone: sanitizeValue(mobilePhoneValue),
                birthday: birthdayValue ? moment(birthdayValue).format('MM/DD/YYYY') : null,
                gender: sanitizeValue(genderValue),
                genderOther: genderValue === 'Other' ? sanitizeValue(genderOtherValue) : null
            },
            company: {
                ...membershipApplication?.company,
                name: companyNameValue,
                businessDescription: businessDescriptionValue,
                typeOfOrg: typeOfOrgValue,
                annualRevenue: annualRevenueValue,
                numberOfEmployee: numberOfEmployeesValue,
                industryNAICS: selectedIndustryDetail.toString(),
                address1: address1Value,
                address2: sanitizeValue(address2Value),
                city: cityValue,
                state: selectedState,
                zip: zipValue,
                country: selectedCountry,
                website: sanitizeValue(websiteValue)
            },
            billingInfo: {
                ...membershipApplication?.billingInfo,
                address1: address1Value,
                address2: sanitizeValue(address2Value),
                city: cityValue,
                state: selectedState,
                zip: zipValue,
                country: selectedCountry,
                companyName: companyNameValue,
            }
        }
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            token: token,
            body: writeToLogs(updatingApplication, membershipApplicationCopy)
        });
        updateApplication(membershipApplicationCopy);
    }
    const updateApplication = (membershipApplicationCopy: MembershipApplication) => {
        setFormError('');
        sendRequest({
            url: updateMembershipApplicationEndpoint(token!),
            method: 'PATCH',
            token: token,
            body: membershipApplicationCopy
        }, (application: MembershipApplication) => {
            updateMembershipApplicationContext(application).then(() => {
                updateSalesforceContact(membershipApplicationCopy);
            });
        }, (error: any) => {
            sendRequest({
                url: writeToLogsEndpoint,
                method: 'POST',
                token: token,
                body: writeToLogs(errorUpdatingApplication, membershipApplicationCopy, error),
            });
            sendRequest({
                url:  config?.emailJSUrl,
                method: 'POST',
                token: token,
                body: generateApplicationErrorEmail(
                    token!,
                    membershipApplicationCopy,
                    config!,
                    error,
                    errorUpdatingApplicationWithIdAndToken(membershipApplication?.membershipApplicationId!, token!)
                ),
                ignoreBaseUrl: true
            });
            setFormHasErrors(true);
            setFormError(errorUpdatingApplication);
        });
    }
    const updateMembershipApplicationContext = async (application: MembershipApplication) => {
        updateMembershipApplication(application);
    }
    const updateSalesforceContact = (membershipApplicationCopy: MembershipApplication) => {
        const contact: Contact = new Contact();
        contact.onyxId = membershipApplicationCopy.prospectOnyxId
        contact.companyName = membershipApplicationCopy.company.name;
        contact.phone = membershipApplicationCopy.person.workPhone;
        if (membershipApplicationCopy.person.suffix) { contact.suffix = membershipApplicationCopy.person.suffix; }
        if (membershipApplicationCopy.person.preferredName) { contact.preferredName = membershipApplicationCopy.person.preferredName; }
        if (membershipApplicationCopy.person.prefix) { contact.salutation = membershipApplicationCopy.person.prefix; }
        if (membershipApplicationCopy.person.middleInitial) { contact.middleName = membershipApplicationCopy.person.middleInitial; }
        if (membershipApplicationCopy.person.mobilePhone) { contact.mobilePhone = membershipApplicationCopy.person.mobilePhone; }
        contact.jobTitle = membershipApplicationCopy.person.jobTitle;
        if (membershipApplicationCopy.person.birthday) { contact.dateOfBirth = membershipApplicationCopy.person.birthday; }
        if (membershipApplicationCopy.person.gender) {
            contact.gender = membershipApplicationCopy.person.gender;
            contact.genderOther = membershipApplicationCopy.person.gender && membershipApplicationCopy.person.gender === 'Other'
                ? membershipApplicationCopy.person.genderOther : null;
        }
        contact.businessDescription = membershipApplicationCopy.company.businessDescription;
        contact.typeOfOrg = membershipApplicationCopy.company.typeOfOrg;
        contact.annualRevenue = membershipApplicationCopy.company.annualRevenue;
        contact.numberOfEmployees = membershipApplicationCopy.company.numberOfEmployee;
        if (membershipApplicationCopy.company.industryNAICS) {
            contact.industry = industryOptions.find((industry) => industry.value === selectedIndustry)?.display;
            contact.industryDetail = industryDetails.find((detail) => detail.value === selectedIndustryDetail)?.display;
        }
        if (membershipApplicationCopy.company.website) { contact.website = membershipApplicationCopy.company.website; }
        if (membershipApplicationCopy.company.address1) { contact.mailingAddressFirst = membershipApplicationCopy.company.address1; }
        if (membershipApplicationCopy.company.address2) { contact.mailingAddressSecond = membershipApplicationCopy.company.address2; }
        if (membershipApplicationCopy.company.city) { contact.mailingCity = membershipApplicationCopy.company.city; }
        if (membershipApplicationCopy.company.state) { contact.mailingState = membershipApplicationCopy.company.state; }
        if (membershipApplicationCopy.company.zip) { contact.mailingZip = membershipApplicationCopy.company.zip; }
        if (membershipApplicationCopy.company.country) {
            contact.mailingCountry = countriesOptions.find((country) => country.value === selectedCountry)?.display;
        }
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            token: token,
            body: contact,
        });
        sendRequest({
            url: updateSalesforceContactEndpoint(membershipApplicationCopy.membershipApplicationId!),
            method: 'PATCH',
            token: token,
            body: contact,
        }, (response: any) => {
            navigate(`/${token}/payment-information`, { replace: true });
        }, (error: any) => {
            sendRequest({
                url: writeToLogsEndpoint,
                method: 'POST',
                token: token,
                body: writeToLogs(errorUpdatingSalesforceContact, contact, error)
            });
            sendRequest({
                url: config?.teamsAlertsUrl,
                method: 'POST',
                token: token,
                body: generateTeamsNotificationPayload(
                    MemberInformation.name,
                    errorUpdatingSalesforceContact,
                    errorUpdatingSalesforceContact + '. Token: ' + token,
                    membershipApplicationCopy,
                    error ? error : null
                ),
                ignoreBaseUrl: true
            });
            navigate(`/${token}/payment-information`, { replace: true });
        });
    }
    return (
        <div>
            {isLoading && <LoadingSpinner/>}
            <Header/>
            <StepNavigation currentStep={2}/>
            {(formHasErrors || showDataLoadError) &&
                <div className={"form-error"}>{formError}</div>
            }
            <h3 className="form-heading margin-top-20">Member Information</h3>
            <Card>
                <div className={"row"}>
                    <div className={"col-md-3 col-sm-6 col-sm-12"}>
                        <label className={"required"}>Full Name</label>
                    </div>
                    <div className={"col-md-1"}>
                        <select value={prefixValue} onChange={prefixChangeHandler}>
                            <option value={""}>--</option>
                            {prefixValues.map((prefix) => (
                                <option value={prefix.value} key={prefix.value}>{prefix.display}</option>
                            ))}
                        </select>
                        <span className={"label"}><i>Prefix</i></span>
                    </div>
                    <div className={"col-md-3"}>
                        <input
                            type={"text"}
                            value={firstNameValue}
                            className={'input-textbox'}
                            readOnly={true}
                        />
                        <span className={"label"}><i>First Name</i></span>
                    </div>
                    <div className={"col-md-1"}>
                        {/* Salesforce has a max limit of 40 characters on Middle Name field */}
                        <input
                            type={"text"}
                            value={middleInitialValue}
                            onChange={middleInitialChangeHandler}
                            className={'input-textbox'}
                            maxLength={40}
                        />
                        <span className={"label"}><i>MI</i></span>
                    </div>
                    <div className={"col-md-3"}>
                        <input
                            type={"text"}
                            value={lastNameValue}
                            className={'input-textbox'}
                            readOnly={true}
                        />
                        <span className={"label"}><i>Last Name</i></span>
                    </div>
                    <div className={"col-md-1"}>
                        <input type={"text"}
                               value={suffixValue}
                               onChange={suffixChangeHandler}
                               className={'input-textbox'}
                               maxLength={10}
                        />
                        <span className={"label"}><i>Suffix</i></span>
                    </div>
                </div>
                <Input
                    label={"Preferred Name"}
                    type={"text"}
                    required={false}
                    readOnly={false}
                    value={preferredNameValue}
                    styles={""}
                    maxLength={40}
                    helpText={"To be shown to other members"}
                    onChange={preferredNameChangeHandler}/>
                <Input
                    label={"Job Title"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={jobTitleValue}
                    styles={formHasErrors && jobTitleValueHasError ? 'input-has-error' : ''}
                    maxLength={80}
                    onChange={jobTitleChangeHandler}/>
                <Input
                    label={"Work Phone"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={workPhoneValue}
                    styles={formHasErrors && workPhoneValueHasError ? 'input-has-error' : ''}
                    maxLength={40}
                    onChange={workPhoneChangeHandler}/>
                <Input
                    label={"Mobile Phone"}
                    type={"text"}
                    required={false}
                    readOnly={false}
                    value={mobilePhoneValue}
                    styles={""}
                    maxLength={40}
                    onChange={mobilePhoneChangeHandler}/>
                <Input
                    label={"Email Address"}
                    type={"text"}
                    required={true}
                    readOnly={true}
                    value={emailValue}
                    styles={""}
                    helpText={"Primary email"}
                    onChange={() => {}}/>
                <div className="row">
                    <div className={"col-md-3 col-sm-6 col-sm-12"}>
                        <label>Date of Birth</label>
                    </div>
                    <div className={"col-md-9"}>
                        <Calendar
                            selectionMode="single"
                            value={birthdayValue}
                            dateFormat="mm/dd/yy"
                            showIcon
                            icon="pi pi-calendar"
                            monthNavigator={true}
                            yearNavigator={true}
                            showButtonBar={true}
                            onChange={(e) => birthdayValueChangeHandler(e.value as Date)}
                            readOnlyInput={true}
                            yearRange={yearRange}
                        />
                    </div>
                </div>
                <Select label={"Gender"}
                        required={false}
                        readOnly={false}
                        value={genderValue}
                        options={genderValues}
                        styles={""}
                        onChange={genderValueChangeHandler}/>
                {genderValue === 'Other'
                    &&
                    <Input
                        label={"Gender Other"}
                        type={"text"}
                        required={true}
                        readOnly={false}
                        value={genderOtherValue}
                        styles={formHasErrors && genderOtherValueHasError ? 'input-has-error' : ''}
                        maxLength={20}
                        onChange={genderOtherValueChangeHandler}
                    />}
            </Card>
            <h3 className="form-heading margin-top-20">Sponsoring Company Information</h3>
            <Card>
                <Input
                    label={"Company Name"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={companyNameValue}
                    styles={formHasErrors && companyNameValueHasError ? 'input-has-error' : ''}
                    maxLength={80}
                    onChange={companyNameChangeHandler}/>
                <div className="row">
                    <div className={"col-md-3 col-sm-6 col-sm-12"}>
                        <label className={"input-required"}>Business Description</label>
                    </div>
                    <div className={"col-md-9"}>
                        <textarea
                            value={businessDescriptionValue}
                            onChange={businessDescriptionValueChangeHandler}
                            maxLength={3200}
                            className={formHasErrors && !isNotEmpty(businessDescriptionValue) ? 'input-has-error' : ''}
                        />
                    </div>
                </div>
                <Select
                    label={"Type of Organization"}
                    required={true}
                    readOnly={false}
                    value={typeOfOrgValue}
                    options={typeOfOrganizationValues}
                    styles={formHasErrors && !isNotEmpty(typeOfOrgValue) ? "input-has-error" : ""}
                    onChange={typeOfOrgValueChangeHandler}
                />
                <Select
                    label={"Annual Revenue"}
                    required={true}
                    readOnly={false}
                    value={annualRevenueValue}
                    options={annualRevenueValues}
                    styles={formHasErrors && !isNotEmpty(annualRevenueValue) ? "input-has-error" : ""}
                    onChange={annualRevenueValueChangeHandler}
                />
                <Select
                    label={"# of Employees"}
                    required={true}
                    readOnly={false}
                    value={numberOfEmployeesValue}
                    options={numberOfEmployeesValues}
                    styles={formHasErrors && !isNotEmpty(numberOfEmployeesValue) ? "input-has-error" : ""}
                    onChange={numberOfEmployeesValueChangeHandler}
                />
                <Select
                    label={"Industry"}
                    required={true}
                    readOnly={false}
                    value={selectedIndustry}
                    options={industryOptions}
                    styles={formHasErrors && !selectedIndustry ? "input-has-error" : ""}
                    onChange={handleIndustryChange}
                />
                <Select
                    label={"Industry Detail"}
                    required={true}
                    readOnly={false}
                    value={selectedIndustryDetail}
                    options={industryDetails}
                    styles={formHasErrors && !selectedIndustryDetail ? "input-has-error" : ""}
                    onChange={handleIndustryDetailChange}
                />

                <Input
                    label={"Address 1"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={address1Value}
                    styles={formHasErrors && address1ValueHasError ? "input-has-error" : ""}
                    onChange={address1ValueChangeHandler}
                    maxLength={255}
                />

                <Input
                    label={"Address 2"}
                    type={"text"}
                    required={false}
                    readOnly={false}
                    value={address2Value}
                    styles={""}
                    onChange={address2ValueChangeHandler}
                    maxLength={100}
                />

                <Input
                    label={"City"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={cityValue}
                    styles={formHasErrors && cityValueHasError ? "input-has-error" : ""}
                    onChange={cityValueChangeHandler}
                    maxLength={40}
                />

                <Select
                    label={"State"}
                    required={true}
                    readOnly={false}
                    value={selectedState}
                    options={statesOptions}
                    styles={formHasErrors && !selectedState ? "select-has-error" : ""}
                    onChange={stateValueChangeHandler}
                />

                <Input
                    label={"Zip"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={zipValue}
                    styles={formHasErrors && zipValueHasError ? "input-has-error" : ""}
                    onChange={zipValueChangeHandler}
                    maxLength={20}
                />

                <Select
                    label={"Country"}
                    required={true}
                    readOnly={selectedCountry === 'US'}
                    value={selectedCountry}
                    options={countriesOptions}
                    styles={formHasErrors && !selectedCountry ? "select-has-error" : ""}
                    onChange={countryValueChangeHandler}
                />

                <Input
                    label={"Website"}
                    type={"text"}
                    required={false}
                    readOnly={false}
                    value={websiteValue}
                    styles={""}
                    onChange={websiteValueChangeHandler}
                    maxLength={255}
                    helpText={"Full URL, such as www.vistage.com"}
                />

            </Card>
            <div className="margin-top-20 nav-buttons-row">
                <NavigationButton text={"Continue"} onClickEventHandler={saveApplication} icon={faAngleRight} iconPlacement={"right"}/>
                <NavigationButton text={"Back"} onClickEventHandler={goBack} icon={faAngleLeft} iconPlacement={"left"}/>
            </div>
            <Footer version={membershipApplication?.agreement.version || ''}/>
        </div>
    )
}
export default MemberInformation;
