import {FC, Fragment, useEffect} from "react";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSearchParams} from "react-router-dom";
import {
    receivedResponseFromPayFabric,
    writeToLogsEndpoint,
    writeToLogs
} from "../../utils/Constants";
import useHttp from "../../hooks/use-http";

const PaymentResponse: FC = () => {
    const [searchParams] = useSearchParams();
    const {sendRequest} = useHttp();
    const resultCode = searchParams.get('ResultCode') || searchParams.get('resultCode');
    const trxKey = searchParams.get('TrxKey') || searchParams.get('trxKey');
    const trxSuccessful = resultCode === 'ACCEPT';
    useEffect(() => {
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            body: writeToLogs(receivedResponseFromPayFabric, {
                resultCode: resultCode,
                trxKey: trxKey
            })
        }, ()=>{}, () => {});
    }, [resultCode, sendRequest, trxKey]);
    useEffect(() => {
        if (trxSuccessful) {
            setTimeout(() => {
                window.parent.postMessage({ trxSuccessful: trxSuccessful }, window.parent.location.href);
            });
        }
        setTimeout(() => {
            window.parent.postMessage({
                trxKey: trxKey,
                resultCode: resultCode
            }, window.parent.location.href);
        }, trxSuccessful ? 5000 : 1000);
    }, [resultCode, trxKey, trxSuccessful]);

    return (
        <Fragment>
            {trxSuccessful &&
                <div className={'iframe-loading-spinner'}>
                    <FontAwesomeIcon icon={faSpinner} size="1x" spinPulse swapOpacity inverse/> Success! Thank you.
                    Please wait while your payment and membership application are being processed. Do not refresh or
                    close this page, or select the back button.
                </div>
            }
        </Fragment>
    )
}

export default PaymentResponse;
