import {FC} from "react";
import Step from "./Step";
import classes from "./StepNavigation.module.css";
type MenuItem = {
    id: number;
    label: string;
    isCurrentStep: boolean;
}

const StepNavigation: FC<{currentStep: number}> = ({currentStep}) => {
    const items: MenuItem[] = [{
        id: 1,
        label: 'Membership Commitments',
        isCurrentStep: currentStep === 1
    }, {
        id: 2,
        label: 'Member Information',
        isCurrentStep: currentStep === 2
    }, {
        id: 3,
        label: 'Payment Information',
        isCurrentStep: currentStep === 3
    }]
    return (
        <div className={classes['step-navigation']}>
            {items.map((item: MenuItem) => (
                <Step step={item.id} text={item.label} isDisabled={!item.isCurrentStep} key={item.id}/>
            ))}
        </div>
    );
}

export default StepNavigation;
