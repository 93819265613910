import {FC} from "react";
import Modal from "../Modal/Modal";
import classes from "./LoadingSpinner.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const LoadingSpinner: FC = () => {
    return (
        <Modal>
            <div className={classes['loading-spinner']}>
                <FontAwesomeIcon icon={faSpinner} size="4x" spinPulse swapOpacity inverse />
            </div>
        </Modal>
    );
}

export default LoadingSpinner;
