import {FC} from "react";
import Modal from "../Modal/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import classes from "./IFrame.module.css";

const IFrame: FC<{ heading: string, url: string|undefined, onClose: any, isPayFabricWindow: boolean }> = ({heading, url, onClose, isPayFabricWindow}) => {
    return (
        <Modal>
            <div className={`${classes.iframe} ${isPayFabricWindow ? classes['payFabric-window'] : ''}`}>
                <div className={`${classes['iframe-header']} ${isPayFabricWindow ? classes['payFabric-window'] : ''}`}>
                    <div className={classes['iframe-header-title']}>
                        <h4>{heading}</h4>
                    </div>
                    {!isPayFabricWindow &&
                        <div className={classes['iframe-header-close-button']}>
                            <FontAwesomeIcon icon={faCircleXmark} onClick={onClose} size={"2x"}/>
                        </div>
                    }
                </div>
                <div className={classes['iframe-content']}>
                    <iframe src={url} className={classes['iframe-body']} title={heading}/>
                </div>
            </div>
        </Modal>
    );
}
export default IFrame;
