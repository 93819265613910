import {FC, Fragment, MouseEventHandler, useState} from "react";
import IFrame from "../IFrame/IFrame";

const IFrameLink: FC<{ textToDisplay: string, heading: string, url: string|undefined }> = ({textToDisplay, heading, url }) => {
    const [showIFrame, setShowIFrame] = useState(false);
    const handleClick: MouseEventHandler = (e) => {
        e.preventDefault();
        setShowIFrame(true);
    };
    const handleClose: MouseEventHandler = () => {
        setShowIFrame(false);
    };
    return (
        <Fragment>
            <a href={url} onClick={handleClick}>{textToDisplay}</a>
            {showIFrame && <IFrame heading={heading} url={url} onClose={handleClose} isPayFabricWindow={false}/>}
        </Fragment>
    );
};

export default IFrameLink;
