export class Contact {
    onyxId!: number;
    companyName?: string;
    phone?: string;
    suffix?: string;
    preferredName?: string;
    salutation?: string;
    middleName?: string;
    mobilePhone?: string;
    jobTitle?: string;
    dateOfBirth?: string;
    gender?: string;
    genderOther?: string | null;
    businessDescription?: string;
    typeOfOrg?: string;
    annualRevenue?: string;
    numberOfEmployees?: string;
    industry?: string;
    industryDetail?: string;
    website?: string;
    mailingAddressFirst?: string;
    mailingAddressSecond?: string;
    mailingCity?: string;
    mailingState?: string;
    mailingZip?: string;
    mailingCountry?: string;
}
