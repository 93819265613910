import {useCallback, useState} from "react";

const getHeaders = (token: string, withPdf: boolean = false)  => {
    return new Headers({
        'Accept': withPdf ? 'application/pdf' : 'application/json',
        'Accept-language': 'en-US, en; q = 0.8',
        'Content-Type': withPdf ? 'application/pdf' : 'application/json',
        'AUTH_TOKEN': token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
    });
}

const useHttp: () => { isLoading: boolean, error: any, statusCode: string|null, sendRequest: any } = () => {
    // TODO fix/remove these refs: const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [statusCode, setStatusCode] = useState<string|null>(null);
    const [activeRequests, setActiveRequests] = useState(0);
    const sendRequest = useCallback(async (requestConfig: any, applyData: any, applyError: any) => {
        // setIsLoading(true);
        setActiveRequests((prev) => prev + 1);
        setError(null);
        try {
            const url = requestConfig.ignoreBaseUrl ? requestConfig.url : `${process.env.REACT_APP_API_URL}${requestConfig.url}`
            const response = await fetch(url, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: getHeaders(requestConfig.token, requestConfig.withPdf),
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
            });
            setStatusCode(response.status.toString());
            const data = requestConfig.withPdf ? await response.blob() : await response.json();
            if (response.status >= 200 && response.status < 299) {
                applyData(data);
                // setIsLoading(false);
            } else {
                setError('Request failed');
                applyError({
                    statusCode: response.status.toString(),
                    message: data
                });
                // setIsLoading(false);
            }
        } catch (err: any) {
            console.log(err.message);
            setError(err.message || 'Something went wrong!');
            // setIsLoading(false);
        } finally {
            // setIsLoading(false);
            setActiveRequests((prev) => prev - 1);
        }
    }, []);
    return {
        isLoading: activeRequests > 0,
        error: error,
        statusCode: statusCode,
        sendRequest: sendRequest,
    };
}
export default useHttp;
