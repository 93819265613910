import React, {FC, Fragment} from "react";
import {BillingRateOption, MembershipApplication} from "../../models/MembershipApplication";
import {
    feesDueLabel, getAmountDueLabel,
    getDiscountsTotal,
    getFirstMonthDue,
    getSelectedMethodOfPaymentText,
    transformAmount
} from "../../utils/Constants";

// TODO: see if any of the functions can be put into constants file

type AmountDueObject = {
    membershipApplication: MembershipApplication;
    billingFrequencyValue: string;
    currentlySelectedBillingFrequency: BillingRateOption | undefined;
    methodOfPaymentValue: string;
    processingFee: number;
}

const AmountDue: FC<AmountDueObject> = (
    {
        membershipApplication,
        billingFrequencyValue,
        currentlySelectedBillingFrequency,
        methodOfPaymentValue,
        processingFee
    }) => {

    const getTotalAmount = () => {
        const enrollmentFee = currentlySelectedBillingFrequency?.enrollmentFee || 0;
        const firstMonthDue = getFirstMonthDue(membershipApplication, currentlySelectedBillingFrequency, methodOfPaymentValue) || 0;
        const discountsTotal = getDiscountsTotal(membershipApplication);
        return enrollmentFee + firstMonthDue + processingFee - discountsTotal;
    }
    const getRemainingBalance = () => {
        if (!currentlySelectedBillingFrequency) {
            return 0;
        }
        if (methodOfPaymentValue === 'INV' || membershipApplication?.groupType === 'EL-SAND') {
            return 0;
        }
        return currentlySelectedBillingFrequency.amount + currentlySelectedBillingFrequency.enrollmentFee - (getTotalAmount() + getDiscountsTotal(membershipApplication));
    }

    return (
        <Fragment>
            <div className={"row margin-top-20"}>
                <div className={"col-md-3 col-sm-6 col-sm-12"}>
                    <b>Amount Due</b>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12"}>
                    <div>You are joining the {membershipApplication?.vistageProgram} program and have selected <b>{billingFrequencyValue}</b> as your payment frequency.</div>
                    {(billingFrequencyValue === "Monthly" || billingFrequencyValue === "Quarterly") &&
                        <div>The total member dues for this {billingFrequencyValue.toLowerCase()} billing is {transformAmount(currentlySelectedBillingFrequency?.amount)} + one-time enrollment fee if applicable.</div>
                    }
                    {(billingFrequencyValue === "Semi-Annually" || billingFrequencyValue === "Annually") &&
                        <div>The total member dues for this {currentlySelectedBillingFrequency?.frequencyLabel.toLowerCase()} billing (with prepay discount) is {transformAmount(currentlySelectedBillingFrequency?.amount)} + one-time enrollment fee if applicable.</div>
                    }
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12"}>
                    <div>Your selected method for recurring payment is: <b>{getSelectedMethodOfPaymentText(methodOfPaymentValue)}{(methodOfPaymentValue === 'INV' || methodOfPaymentValue === 'CC') && processingFee > 0 && <sup>*</sup>}</b></div>
                    {methodOfPaymentValue === "INV" && processingFee > 0 &&
                        <div className={"help-text margin-left-10"}><sup>*</sup><i>A processing fee of up to 3% of membership dues, after applicable discounts, will apply to payments where the recurring payment method is invoice. To avoid this charge, contact Vistage Billing to switch to EFT.</i></div>
                    }
                    {methodOfPaymentValue === "CC" && processingFee > 0 &&
                        <div className={"help-text margin-left-10"}><sup>*</sup><i>A processing fee of up to 3% of membership dues, after applicable discounts, will apply to payments where the recurring payment method is credit card. The amount charged will be no more than the processing fees incurred by Vistage to process the credit card payment. To avoid this charge, contact Vistage Billing to switch to EFT.</i></div>
                    }
                </div>
            </div>
            {currentlySelectedBillingFrequency &&
                <div className={"amount-due-container margin-top-20"}>
                    <div className={"row"}>
                        <div className={"col-md-12 font-weight-bold"}>
                            {getAmountDueLabel(membershipApplication, methodOfPaymentValue)}
                        </div>
                    </div>
                    {membershipApplication?.groupType !== 'EL-SAND' &&
                        <div className={"row margin-0"}>
                            <div className={"col-md-4 col-sm-12"}>Enrollment Fee</div>
                            <div className={"col-md-2 col-sm-12 text-right"}>{transformAmount(currentlySelectedBillingFrequency.enrollmentFee)}</div>
                            <div className={"col-md-5 col-sm-12"}></div>
                        </div>
                    }
                    <div className={"row margin-0"}>
                        <div className={"col-md-4 col-sm-12"}>{feesDueLabel(membershipApplication.groupType, currentlySelectedBillingFrequency, methodOfPaymentValue)}</div>
                        <div className={"col-md-2 col-sm-12 text-right"}>{transformAmount(getFirstMonthDue(membershipApplication, currentlySelectedBillingFrequency, methodOfPaymentValue))}</div>
                        {membershipApplication?.groupType === 'EL-SAND' && currentlySelectedBillingFrequency.frequency === 'Annually' &&
                            <div className={"col-md-5 col-sm-12"}>
                                *Program fee reflects the first year's fees only.
                            </div>
                        }
                    </div>
                    {(methodOfPaymentValue === 'INV' || methodOfPaymentValue === 'CC') && processingFee > 0 &&
                        <div className={"row margin-0"}>
                            {methodOfPaymentValue === 'INV' && <div className={"col-md-4 col-sm-12"}>Invoice Processing Fee</div>}
                            {methodOfPaymentValue === 'CC' && <div className={"col-md-4 col-sm-12"}>Credit Card Processing Fee</div>}
                            <div className={"col-md-2 col-sm-12 text-right"}>{transformAmount(processingFee)}</div>
                            <div className={"col-md-5 col-sm-12"}></div>
                        </div>
                    }
                    {membershipApplication?.appliedDiscounts && membershipApplication?.appliedDiscounts?.length > 0 &&
                        <div className={"row margin-0"}>
                            <div className={"col-md-4 col-sm-12"}>Promotion</div>
                            <div className={"col-md-2 col-sm-12 text-right"}>({transformAmount(getDiscountsTotal(membershipApplication))})</div>
                            <div className={"col-md-5 col-sm-12"}>{membershipApplication.appliedDiscounts[0].name}</div>
                        </div>
                    }
                    <div className={"row margin-top-20 font-weight-bold"}>
                        <div className={"col-md-4 col-sm-12"}>Total</div>
                        <div className={"col-md-2 col-sm-12 text-right"}>{transformAmount(getTotalAmount())}</div>
                        <div className={"col-md-5 col-sm-12"}></div>
                    </div>
                </div>
            }
            {methodOfPaymentValue && currentlySelectedBillingFrequency && getRemainingBalance() > 0 &&
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12"}>
                        The remaining amount of {transformAmount(getRemainingBalance())} will be charged at the start of your membership. {(currentlySelectedBillingFrequency.frequency === 'Semi-Annually' || currentlySelectedBillingFrequency.frequency === 'Annually') ? 'This includes the prepay discount for your first month.' : ''}
                    </div>
                </div>
            }
            {membershipApplication?.appliedDiscounts && membershipApplication?.appliedDiscounts?.length > 0 &&
                <div className={"row"}>
                    <div className={"col-md-12 col-sm-12"}>
                        Promotion discounts are subject to eligibility. Upon review of the application by Vistage, you will be charged if an ineligible discount was applied.
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default AmountDue;
