import React, {ChangeEvent, FC, Fragment, useContext, useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import StepNavigation from "../../components/StepNavigation/StepNavigation";
import Footer from "../../components/Footer/Footer";
import MembershipApplicationContext from "../../store/membership-application-context";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useHttp from "../../hooks/use-http";
import Input from "../../components/Input/Input";
import Card from "../../components/Card/Card";
import NavigationButton from "../../components/NavigationButton/NavigationButton";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import {useForceNavigate} from "../../guards/TokenValidationGuard";
import {useNavigate, useParams} from "react-router-dom";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import moment from "moment";
import Select from "../../components/Select/Select";
import {
    countriesEndpoint,
    errorLoadingDataMessage,
    errorPostingToGP,
    errorUpdatingApplication,
    errorUpdatingApplicationWithIdAndToken,
    failedToLoadCountryData,
    failedToLoadStatesData,
    getDiscountsTotal,
    getFirstMonthDue,
    getProcessPaymentEndpoint,
    isEmailValid,
    isNotEmpty,
    isRoutingNumberValid,
    loadingPaymentInformation,
    paymentMethods,
    generateTeamsNotificationPayload,
    processingPayment,
    sanitizeValue,
    generateApplicationErrorEmail,
    generateGPErrorEmail,
    statesEndpoint,
    taPaymentTypes,
    updateMembershipApplicationEndpoint,
    updatingApplication,
    writeToLogsEndpoint,
    writeToLogs,
    createPayFabricTokenEndpoint,
    errorGenerationPayFabricTransaction,
    errorGeneratingPayFabricToken,
    createPayFabricTransactionEndpoint,
    errorPayFabricDefault,
    getPayFabricProcessTrxUrl,
    receivedEvent,
    duplicatePaymentMessageTitle,
    duplicatePaymentMessageContent,
    receivedTransactionResponseAndFetchingTrx,
    getTransactionEndpoint,
    getErrorMessageForResultCode,
    isSystemError,
    systemError,
    duplicateMessagesReceived,
    failedToRetrieveTransaction,
    successfullyUpdatedApplicationWithNoTrxDetails,
    errorUpdatingApplicationWithNoTrxDetails,
    getDefaultPaymentObject,
    getCCPaymentObject,
    maxRetryCount,
    retryingUpdateApplication,
    errorUpdatingApplicationAfterTrxSuccess,
    retryingProcessingPayment,
    errorPostingToGPAfterSuccessfulTrx
} from "../../utils/Constants";
import {BillingRateOption, MembershipApplication} from "../../models/MembershipApplication";
import useInput from "../../hooks/use-input";
import {Country} from "../../models/Country";
import {State} from "../../models/State";
import IFrameLink from "../../components/IFrameLink/IFrameLink";
import AuthorizedSignerForm from "./AuthorizedSignerForm";
import EFTHelpText from "./EFTHelpText";
import AmountDue from "./AmountDue";
import AppContext from "../../store/app-context";
import {Payment} from "../../models/Payment";
import {CreateTransactionRequest} from "../../models/CreateTransactionRequest";
import IFrame from "../../components/IFrame/IFrame";
import PostMessageListener from "../../components/PostMessageListener/PostMessageListener";
import {useMessageBox} from "../../store/message-box-context";
import {Transaction} from "../../models/Transaction";

const PaymentInformation: FC = () => {
    const {token} = useParams<{ token: string }>();
    const {isLoading, sendRequest} = useHttp();
    const forceNavigate = useForceNavigate();
    const navigate = useNavigate();
    const {membershipApplication, updateMembershipApplication} = useContext(MembershipApplicationContext);
    const today = moment(new Date()).format("dddd, MMMM DD, YYYY");
    const [formHasErrors, setFormHasErrors] = useState<boolean>(false);
    const [formError, setFormError] = useState('');
    const [showDataLoadError, setShowDataLoadError] = useState(false);
    let payFabricToken: string | null = null;
    let payFabricTrxKey: string | null = null;
    let payment: Partial<Payment> | null = null;
    const [payFabricIframeUrl, setPayFabricIframeUrl] = useState<string|null>(null);
    const [showPayFabricIFrame, setShowPayFabricIFrame] = useState(false);
    const { showMessage } = useMessageBox();

    useEffect(() => {
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            token: token,
            body: writeToLogs(loadingPaymentInformation(token))
        }, ()=>{}, () => {})
    }, [sendRequest, token]);
    const {config} = useContext(AppContext);

    const [billingStartMonthText, setBillingStartMonthText] = useState('');
    const [methodOfPaymentValue, setMethodOfPaymentValue]= useState(membershipApplication?.methodOfPayment || "");
    const [billingFrequencyValue, setBillingFrequencyValue]= useState(membershipApplication?.billingFrequency || "");
    const [currentlySelectedBillingFrequency, setCurrentlySelectedBillingFrequencyValue]= useState<BillingRateOption | undefined>(undefined);
    const methodOfPaymentValueChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        setMethodOfPaymentValue(event.target.value);
        setBillingFrequencyValue("");
        setFormHasErrors(false);
        setFormError('');
    }
    const billingFrequencyValueChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        setBillingFrequencyValue(event.target.value);
        setCurrentlySelectedBillingFrequencyValue(membershipApplication?.billingRateOption.find((option) => option.frequency === event.target.value));
    }
    const frequencyOptions = membershipApplication?.billingRateOption?.filter(
        (option) => option.methodOfPayment.includes(methodOfPaymentValue)).map((option) => ({
        value: option.frequency,
        display: option.label
    })) || [];

    const defaultCompanyNameValue = membershipApplication?.billingInfo.companyName || '';
    const isCompanyNameValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: companyNameValue,
        hasError: companyNameValueHasError,
        valueChangeHandler: companyNameValueChangeHandler
    } = useInput(defaultCompanyNameValue, isCompanyNameValueValid);

    const defaultAddress1Value = membershipApplication?.billingInfo.address1 || '';
    const isAddress1Valid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: address1Value,
        hasError: address1ValueHasError,
        valueChangeHandler: address1ValueChangeHandler
    } = useInput(defaultAddress1Value, isAddress1Valid);

    const [address2Value, setAddress2Value] = useState(membershipApplication?.billingInfo.address2 || '');
    const address2ValueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setAddress2Value(event.target.value);
    }

    const defaultCityValue = membershipApplication?.billingInfo.city || '';
    const isCityValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: cityValue,
        hasError: cityValueHasError,
        valueChangeHandler: cityValueChangeHandler
    } = useInput(defaultCityValue, isCityValueValid);

    const zipDefaultValue = membershipApplication?.billingInfo.zip || '';
    const isZipValueValid = (value: string) => {
        return isNotEmpty(value);
    }
    const {
        value: zipValue,
        hasError: zipValueHasError,
        valueChangeHandler: zipValueChangeHandler
    } = useInput(zipDefaultValue, isZipValueValid);

    const [countries, setCountries] = useState<Country[]>([]);
    const [states, setStates] = useState<State[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string>(membershipApplication?.billingInfo.country || 'US');
    const [selectedState, setSelectedState] = useState<string>(membershipApplication?.billingInfo.state || '');
    useEffect(() => {
        sendRequest({
            url: countriesEndpoint,
            method: 'GET',
            token: token,
        }, (response: any) => {
            setCountries(response.countries);
        }, (error: any) => {
            sendRequest({
                url: writeToLogsEndpoint,
                method: 'POST',
                token: token,
                body: writeToLogs(failedToLoadCountryData(token), undefined, error)
            }, ()=>{}, () => {})
            setFormError(errorLoadingDataMessage);
            setShowDataLoadError(true);
        });
    }, [sendRequest, token]);
    const countriesOptions = countries.map((country: Country) => ({
        value: country.countryCode,
        display: country.name,
    }));
    const countryValueChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCountry(event.target.value);
    }

    useEffect(() => {
        if (selectedCountry === 'US' || selectedCountry === 'CA') {
            sendRequest({
                url: statesEndpoint(selectedCountry),
                method: 'GET',
                token: token,
            }, (response: any) => {
                setStates(response.states);
                const foundState = response.states.find((state: State) => {
                    return state.stateCode === membershipApplication?.company.state;
                });
                if (foundState) {
                    setSelectedState(foundState.stateCode);
                }
            }, (error: any) => {
                sendRequest({
                    url: writeToLogsEndpoint,
                    method: 'POST',
                    token: token,
                    body: writeToLogs(failedToLoadStatesData(token), undefined, error)
                }, ()=>{}, () => {})
                setFormError(errorLoadingDataMessage);
                setShowDataLoadError(true);
            })
        } else {
            setStates([]);
        }
    }, [membershipApplication?.company.state, selectedCountry, sendRequest, token]);
    const statesOptions = states.map((state: State) => ({
        value: state.stateCode,
        display: state.name
    }));
    const stateValueChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedState(event.target.value);
    }

    const defaultBillingContactName = membershipApplication?.billingInfo.contactName || '';
    const {
        value: billingContactNameValue,
        valueChangeHandler: billingContactNameValueChangeHandler
    } = useInput(defaultBillingContactName, () => {return true});

    const defaultBillingContactEmail = membershipApplication?.billingInfo.contactEmail || '';
    const {
        value: billingContactEmailValue,
        hasError: billingContactEmailValueHasError,
        valueChangeHandler: billingContactEmailValueChangeHandler
    } = useInput(defaultBillingContactEmail, (value: string) => { return isNotEmpty(value) && isEmailValid(value) });

    const defaultBillingContactPhone = membershipApplication?.billingInfo.contactPhone || '';
    const {
        value: billingContactPhoneValue,
        valueChangeHandler: billingContactPhoneValueChangeHandler
    } = useInput(defaultBillingContactPhone, () => {return true});

    useEffect(() => {
        if (membershipApplication?.startDate === null) {
            setBillingStartMonthText("Group Start Date");
        } else {
            const dateObj = moment(membershipApplication?.startDate);
            setBillingStartMonthText(dateObj.format("MMMM YYYY"));
        }
    }, [membershipApplication?.startDate]);

    useEffect(() => {
        if (membershipApplication) {
            setMethodOfPaymentValue(membershipApplication.methodOfPayment);
            setBillingFrequencyValue(membershipApplication.billingFrequency);
            setCurrentlySelectedBillingFrequencyValue(membershipApplication?.billingRateOption.find((option) => option.frequency === membershipApplication.billingFrequency));
        }
    }, [membershipApplication]);

    const [processingFee, setProcessingFee] = useState(0);
    useEffect(() => {
        if (currentlySelectedBillingFrequency && methodOfPaymentValue === "EFT") {
            setProcessingFee(0);
        } else if (currentlySelectedBillingFrequency && methodOfPaymentValue === "INV") {
            setProcessingFee(currentlySelectedBillingFrequency?.invProcessingFee || 0);
        } else  {
            setProcessingFee(currentlySelectedBillingFrequency?.ccProcessingFee || 0);
        }
    }, [currentlySelectedBillingFrequency, methodOfPaymentValue]);

    const [isTermsAcceptedValue, setIsTermsAcceptedValue] = useState(false);
    const isTermsAcceptedValueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setIsTermsAcceptedValue(event.target.checked);
    }

    const [termsAcceptedNameValue, setTermsAcceptedNameValue] = useState<string>(membershipApplication?.financialTermsAcceptanceName || '');
    const termsAcceptedNameValueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setTermsAcceptedNameValue(event.target.value);
    }

    const {
        value: financialInstitutionValue,
        valueChangeHandler: financialInstitutionValueChangeHandler,
        hasError: financialInstitutionValueHasError
    } = useInput('', isNotEmpty);

    const validateRoutingNumber = (value: string) => {
        return isNotEmpty(value) && value.trim().length === 9 && isRoutingNumberValid(value);
    }
    const {
        value: routingNumberValue,
        valueChangeHandler: routingNumberValueChangeHandler,
        hasError: routingNumberValueHasError
    } = useInput('', validateRoutingNumber);

    const {
        value: accountNumberValue,
        valueChangeHandler: accountNumberValueChangeHandler,
        hasError: accountNumberValueHasError
    } = useInput('', isNotEmpty);

    const [taThankYouTypeValue, setTThankYouTypeValue] = useState<string>(membershipApplication?.billingInfo.taThankType || "");
    const taThankYouTypeValueChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTThankYouTypeValue(event.target.value);
    }

    const [isProspectAuthorizerValue, setIsProspectAuthorizerValue] = useState<boolean>(membershipApplication?.isProspectAuthorizer || true);
    const [authorizerNameValue, setAuthorizerNameValue] = useState<string>(membershipApplication?.authorizerName || '');
    const [authorizerTitleValue, setAuthorizerTitleValue] = useState<string>(membershipApplication?.authorizerTitle || '');
    const [authorizerEmailValue, setAuthorizerEmailValue] = useState<string>(membershipApplication?.authorizerEmail || '');

    const scrollToTop = () => {
        // TODO: maybe add a timeout?
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    const logInformation = (body: any) => {
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            body: body
        });
    }
    const sendEmailJSNotification = (token: string, body: any) => {
        sendRequest({
            url:  config?.emailJSUrl,
            method: 'POST',
            token: token,
            body: body,
            ignoreBaseUrl: true
        });
    }
    const sendTeamsNotification = (token: string, body: any) => {
        sendRequest({
            url: config?.teamsAlertsUrl,
            method: 'POST',
            token: token,
            body: body,
            ignoreBaseUrl: true
        });
    }
    const getTotalAmount = () => {
        const enrollmentFee = currentlySelectedBillingFrequency?.enrollmentFee || 0;
        const firstMonthDue = getFirstMonthDue(membershipApplication!, currentlySelectedBillingFrequency, methodOfPaymentValue) || 0;
        const discountsTotal = getDiscountsTotal(membershipApplication!);
        return enrollmentFee + firstMonthDue + processingFee - discountsTotal;
    }
    const updateMembershipApplicationContext = async (application: MembershipApplication) => {
        updateMembershipApplication(application);
    }
    const sendAuthorizerEmailHandler = (name: string, title: string, email: string) => {
        setAuthorizerNameValue(name);
        setAuthorizerTitleValue(title);
        setAuthorizerEmailValue(email);
        // update application
            // success - send email
            // error - show error
        // send email
            // success - navigate to thank you
            // failure - show error?
    }
    const goBack= () => {
        // TODO: handle state update
        forceNavigate(`/${token}/member-information`);
    }
    const saveApplication = () => {
        setFormError('');
        if (!methodOfPaymentValue || !billingFrequencyValue || !companyNameValue
            || address1ValueHasError || cityValueHasError || zipValueHasError || !selectedState || !selectedCountry
            || billingContactEmailValueHasError || !isTermsAcceptedValue) {
            setFormHasErrors(true);
            setFormError('*Please complete the required fields.');
            scrollToTop();
            return;
        }
        if (methodOfPaymentValue && methodOfPaymentValue === 'EFT' && (
            financialInstitutionValueHasError || routingNumberValueHasError || accountNumberValueHasError)) {
            setFormHasErrors(true);
            setFormError('*Please complete the required fields.');
            scrollToTop();
            return;
        }
        if (membershipApplication?.billingInfo.taThankType === 'TA-SAND' && !taThankYouTypeValue) {
            setFormHasErrors(true);
            setFormError('*Please complete the required fields.');
            scrollToTop();
            return;
        }
        const membershipApplicationCopy: MembershipApplication = {
            ...membershipApplication!,
            methodOfPayment: methodOfPaymentValue,
            billingFrequency: billingFrequencyValue,
            financialTermsAccepted: isTermsAcceptedValue,
            financialTermsAcceptanceName: termsAcceptedNameValue,
            billingInfo: {
                contactName: sanitizeValue(billingContactNameValue),
                contactEmail: billingContactEmailValue,
                contactPhone: sanitizeValue(billingContactPhoneValue),
                address1: address1Value,
                address2: sanitizeValue(address2Value),
                city: cityValue,
                state: selectedState,
                zip: zipValue,
                country: selectedCountry,
                taThankType: sanitizeValue(taThankYouTypeValue),
                companyName: companyNameValue,
            },
            isProspectAuthorizer: !membershipApplication?.isAlternatePayerAllowed ? null : isProspectAuthorizerValue
        }
        if (methodOfPaymentValue !== 'CC') {
            membershipApplicationCopy.ccProcessingSuccess = true;
            membershipApplicationCopy.ccAuthorizationResponsesCode = 1000;
            membershipApplicationCopy.stepThreeSaved = true;
            membershipApplicationCopy.submitted = true;

        }
        logInformation(writeToLogs(updatingApplication, membershipApplicationCopy));
        updateApplication(membershipApplicationCopy);
    }
    const updateApplication = (membershipApplicationCopy: MembershipApplication) => {
        setFormError('');
        setFormHasErrors(false);
        sendRequest({
            url: updateMembershipApplicationEndpoint(token!),
            method: 'PATCH',
            token: token,
            body: membershipApplicationCopy
        }, (application: MembershipApplication) => {
            updateMembershipApplicationContext(application).then(() => {
                processPayment(membershipApplicationCopy);
            });
        }, (error: any) => {
            logInformation(writeToLogs(errorUpdatingApplicationWithIdAndToken(membershipApplicationCopy?.membershipApplicationId!, token!), membershipApplicationCopy, error))
            sendEmailJSNotification(token!, generateApplicationErrorEmail(
                token!, membershipApplicationCopy, config!,
                error, errorUpdatingApplicationWithIdAndToken(membershipApplication?.membershipApplicationId!, token!)));
            sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name, errorUpdatingApplication,
                errorUpdatingApplicationWithIdAndToken(membershipApplicationCopy.membershipApplicationId!, token!),
                membershipApplicationCopy,
                error ? error : null
            ));
            setFormHasErrors(true);
            setFormError(errorUpdatingApplication);
            scrollToTop();
        });
    }
    const processPayment = (membershipApplicationCopy: MembershipApplication) => {
        payment = getDefaultPaymentObject(membershipApplicationCopy, methodOfPaymentValue, getTotalAmount().toString());
        if (methodOfPaymentValue === 'CC') {
            processPaymentForCreditCard(membershipApplicationCopy);
            return;
        }
        payment.ccType = null;
        if (methodOfPaymentValue === 'EFT') {
            payment.financialInstitution = financialInstitutionValue || '';
            payment.accountNumber = accountNumberValue || '';
            payment.routingNumber = routingNumberValue || '';
        }
        // write data to GP for EFT/INV
        logInformation(writeToLogs(processingPayment(token), payment));
        sendRequest({
            url: getProcessPaymentEndpoint(),
            method: 'POST',
            token: token!,
            body: payment,
        }, () => {
            navigate(`/thank-you?token=${token}${methodOfPaymentValue === 'INV' ? '&isInv=true' : ''}`, { replace: true });
        }, (error: any) => {
            sendEmailJSNotification(token!, generateApplicationErrorEmail(token!, membershipApplicationCopy, config!, {
                paymentInformation: JSON.stringify(payment),
                error: error
            }, errorPostingToGP));
            sendEmailJSNotification(token!, generateGPErrorEmail(membershipApplicationCopy, config!, payment!))
            sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name, errorPostingToGP, `${errorPostingToGP} Token: ${token}`, payment, error));
            logInformation(writeToLogs(errorPostingToGP, {
                applicationId: membershipApplicationCopy.membershipApplicationId,
                payment: payment
            }, error));
            navigate(`/thank-you?token=${token}${methodOfPaymentValue === 'INV' ? '&isInv=true' : ''}`, { replace: true });
        });
    }
    const processPaymentForCreditCard = (membershipApplicationCopy: MembershipApplication) => {
        const createTrxRequest: CreateTransactionRequest = new CreateTransactionRequest(
            membershipApplicationCopy.customerID,
            getTotalAmount(),
            membershipApplicationCopy.isDelayedPayment,
            config?.currentEnvironment === 'prod');
        sendRequest({
            url: createPayFabricTokenEndpoint,
            method: 'GET',
            token: token
        }, (response: any) => {
            payFabricToken = response.token;
            sendRequest({
                url: createPayFabricTransactionEndpoint(membershipApplicationCopy.membershipApplicationId!),
                method: 'POST',
                token: token,
                body: createTrxRequest
            }, (response: any) => {
                payFabricTrxKey = response.key;
                const url = getPayFabricProcessTrxUrl(payFabricTrxKey!, config?.payFabricBaseUrl!, payFabricToken!,
                    membershipApplicationCopy.billingInfo.address1 || '',
                    membershipApplicationCopy.billingInfo.address2 || '',
                    membershipApplicationCopy.billingInfo.city || '',
                    membershipApplicationCopy.billingInfo.state || '',
                    membershipApplicationCopy.billingInfo.zip || '',
                    membershipApplicationCopy.billingInfo.country || '',
                    membershipApplicationCopy.billingInfo.contactEmail || '',
                    membershipApplicationCopy.isDelayedPayment);
                setPayFabricIframeUrl(url);
                setShowPayFabricIFrame(true);
            }, (error: any) => {
                if (error.message.status === 409) {
                    showMessage(duplicatePaymentMessageTitle, duplicatePaymentMessageContent, {
                        onClick: () => handleTrx(JSON.parse(error.message.message)),
                    });
                    return;
                }
                setFormHasErrors(true);
                setFormError(errorPayFabricDefault);
                sendEmailJSNotification(token!,generateApplicationErrorEmail(token!, membershipApplicationCopy, config!, {
                    createTrxRequest: JSON.stringify(createTrxRequest),
                    error: error
                }, errorGenerationPayFabricTransaction));
                sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name,
                    errorGenerationPayFabricTransaction, errorGenerationPayFabricTransaction + ' MembershipApplication Token: ' + token, createTrxRequest, error));
                scrollToTop();
            });
        }, (error: any) => {
            setFormHasErrors(true);
            setFormError(errorPayFabricDefault);
            sendEmailJSNotification(token!,generateApplicationErrorEmail(token!, membershipApplicationCopy, config!, {
                paymentInformation: JSON.stringify(payment),
                error: error
            }, errorGeneratingPayFabricToken));
            sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name,
                errorGeneratingPayFabricToken, errorGeneratingPayFabricToken + ' MembershipApplication Token: ' + token, null, error));
            scrollToTop();
        });
    }
    const handleTrx = ({ trxKey, resultCode }: { trxKey: string; resultCode: string }) => {
        logInformation(writeToLogs(receivedTransactionResponseAndFetchingTrx, {
            transactionKey: trxKey,
            resultCode: resultCode,
            applicationId: membershipApplication?.membershipApplicationId
        }));
        sendRequest({
            url: getTransactionEndpoint(membershipApplication?.membershipApplicationId!, trxKey),
            method: 'GET',
            token: token
        }, (response: Transaction) => {
            if (!response) {
                handleTrxWithoutTrxDetails(trxKey, resultCode);
            }
            const transaction: Transaction = response;
            if (transaction.trxResponse.resultCode === 'ACCEPT' && transaction.trxResponse.status === 'Approved') {
                handleTrxSuccess(transaction);
            } else {
                handleTrxFailure(transaction.trxResponse.resultCode, transaction.trxResponse.status);
            }
        }, () => {
            handleTrxWithoutTrxDetails(trxKey, resultCode);
        })
    }
    const handleTrxSuccess = (transaction: Transaction) => {
        const membershipApplicationCopy: MembershipApplication = {
            ...membershipApplication!,
            ccProcessingSuccess: true,
            ccAuthorizationResponsesCode: 0,
            stepThreeSaved: true,
            submitted: true
        }
        payment = getCCPaymentObject(membershipApplicationCopy, transaction, methodOfPaymentValue, getTotalAmount().toString());
        updateApplicationAfterTrxSuccess(membershipApplicationCopy, transaction, payment, maxRetryCount);
    }
    const updateApplicationAfterTrxSuccess = (membershipApplicationCopy: MembershipApplication, transaction: Transaction, payment: Partial<Payment>, retryCount: number) => {
        logInformation(writeToLogs(updatingApplication, membershipApplicationCopy));
        if (retryCount > 0) {
            sendRequest({
                url: updateMembershipApplicationEndpoint(token!),
                method: 'PATCH',
                token: token!,
                body: membershipApplicationCopy
            }, (response: any) => {
                updateMembershipApplicationContext(response).then(() => {
                    postToGPAfterTrxSuccess(membershipApplicationCopy, transaction, payment, maxRetryCount);
                });
            }, (error: any) => {
                retryCount -= 1;
                if (retryCount > 0) {
                    logInformation(writeToLogs(retryingUpdateApplication, {
                        retryCount: retryCount,
                        application: membershipApplicationCopy
                    }, error));
                    updateApplicationAfterTrxSuccess(membershipApplicationCopy, transaction, payment, retryCount);
                } else {
                    sendEmailJSNotification(token!, generateApplicationErrorEmail(token!, membershipApplicationCopy, config!, {
                        paymentInformation: JSON.stringify(payment),
                        trxKey: transaction.trxResponse.trxKey,
                        resultCode: transaction.trxResponse.resultCode,
                        status: transaction.trxResponse.status,
                        error: error
                    }, errorUpdatingApplicationAfterTrxSuccess));
                    sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name, errorUpdatingApplication, errorUpdatingApplicationAfterTrxSuccess, membershipApplicationCopy, error));
                    sendEmailJSNotification(token!, generateGPErrorEmail(membershipApplicationCopy, config!, payment, transaction.trxResponse.trxKey));
                    logInformation(writeToLogs(errorUpdatingApplicationAfterTrxSuccess, membershipApplicationCopy, error));
                    navigate(`/thank-you?token=${token}`, { replace: true });
                }
            });
        }
    }
    const postToGPAfterTrxSuccess = (membershipApplicationCopy: MembershipApplication, transaction: Transaction, payment: Partial<Payment>, retryCount: number) => {
        logInformation(writeToLogs(processingPayment(token!), payment));
        if (retryCount > 0) {
            sendRequest({
                url: getProcessPaymentEndpoint(membershipApplicationCopy.isDelayedPayment),
                method: 'POST',
                token: token!,
                body: payment
            }, (response: any) => {
                navigate(`/thank-you?token=${token}`, { replace: true });
            }, (error: any) => {
                retryCount -= 1;
                if (retryCount > 0) {
                    logInformation(writeToLogs(retryingProcessingPayment, {
                        retryCount: retryCount,
                        payment: payment,
                    }, error));
                    postToGPAfterTrxSuccess(membershipApplicationCopy, transaction, payment, retryCount);
                } else {
                    sendEmailJSNotification(token!, generateApplicationErrorEmail(token!, membershipApplicationCopy, config!, {
                        paymentInformation: JSON.stringify(payment),
                        trxKey: transaction.trxResponse.trxKey,
                        resultCode: transaction.trxResponse.resultCode,
                        status: transaction.trxResponse.status,
                        error: error
                    }, errorPostingToGPAfterSuccessfulTrx));
                    sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name, errorPostingToGP, errorPostingToGP, payment, error));
                    sendEmailJSNotification(token!, generateGPErrorEmail(membershipApplicationCopy, config!, payment, transaction.trxResponse.trxKey));
                    logInformation(writeToLogs(errorPostingToGPAfterSuccessfulTrx, payment, error));
                    navigate(`/thank-you?token=${token}`, { replace: true });
                }
            });
        }
    }
    const handleTrxWithoutTrxDetails = (trxKey: string, resultCode: string) => {
        logInformation(writeToLogs(failedToRetrieveTransaction, {
            applicationId: membershipApplication?.membershipApplicationId!,
            transactionKey: trxKey,
            resultCode: resultCode
        }));
        if (resultCode !== 'ACCEPT') {
            handleTrxFailure(resultCode, '');
        } else {
            // trx successful but trx-details not available
            // submit application and notify billing and dev
            // navigate to thank-you page
            const membershipApplicationCopy: MembershipApplication = {
                ...membershipApplication!,
                ccProcessingSuccess: true,
                ccAuthorizationResponsesCode: 0,
                stepThreeSaved: true,
                submitted: true
            }
            logInformation(writeToLogs(updatingApplication, membershipApplicationCopy));
            sendRequest({
                url: updateMembershipApplicationEndpoint(token!),
                method: 'PATCH',
                token: token,
                body: membershipApplicationCopy
            }, () => {
                sendEmailJSNotification(token!, generateApplicationErrorEmail(token!, membershipApplicationCopy, config!, {
                    paymentInformation: JSON.stringify(payment),
                    trxKey: trxKey,
                    resultCode: resultCode
                }, successfullyUpdatedApplicationWithNoTrxDetails));
                sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name, successfullyUpdatedApplicationWithNoTrxDetails, successfullyUpdatedApplicationWithNoTrxDetails));
                sendEmailJSNotification(token!, generateGPErrorEmail(membershipApplicationCopy, config!, payment!, trxKey))
                navigate(`/thank-you?token=${token}`, { replace: true });
            }, (error: any) => {
                // navigate to thank you
                sendEmailJSNotification(token!, generateApplicationErrorEmail(token!, membershipApplicationCopy, config!, {
                    paymentInformation: JSON.stringify(payment),
                    trxKey: trxKey,
                    resultCode: resultCode,
                    error: error
                }, errorUpdatingApplicationWithNoTrxDetails));
                sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name, errorUpdatingApplicationWithNoTrxDetails, errorUpdatingApplicationWithNoTrxDetails, null, error));
                sendEmailJSNotification(token!, generateGPErrorEmail(membershipApplicationCopy, config!, payment!, trxKey))
                logInformation(writeToLogs(errorUpdatingApplicationWithNoTrxDetails, membershipApplicationCopy, error));
                navigate(`/thank-you?token=${token}`, { replace: true });
            });
        }
    }
    const handleTrxFailure = (errorCode: string, status: string) => {
        const trxError = getErrorMessageForResultCode(errorCode, status);
        setFormHasErrors(true);
        setFormError(trxError);
        if (isSystemError(errorCode)) {
            sendEmailJSNotification(token!, generateApplicationErrorEmail(token!, membershipApplication!, config!, {
                error: trxError,
                errorCode: errorCode,
                paymentInformation: JSON.stringify(payment)
            }, systemError));
            sendTeamsNotification(token!, generateTeamsNotificationPayload(PaymentInformation.name, systemError, systemError));
        }
        setShowPayFabricIFrame(false);
        scrollToTop();
    }

    const handleEventMessage = (origin: string, message: any) => {
        logInformation(writeToLogs(receivedEvent, {
            origin: origin,
            message: message
        }));
        if (typeof message === 'string' && message === 'closePaymentIFrame') {
            setShowPayFabricIFrame(false);
            setPayFabricIframeUrl(null);
            return;
        }
        if (typeof message === 'string' && message === 'paymentIFrameLoaded') {
            // TODO do something?
            return;
        }
        if (typeof message === 'object' && message.trxSuccessful) {
            // TODO do something?
            return;
        }
        const timeStamp = localStorage.getItem('iframe-message-timestamp')
        if (timeStamp !== null) {
            if ((new Date().getTime() - +timeStamp) <= 5*1000 ) {
                // ignore duplicate messages
                logInformation(writeToLogs(duplicateMessagesReceived, {
                    applicationId: membershipApplication?.membershipApplicationId!,
                    timeStamp: localStorage.getItem('iframe-message-timestamp')
                }));
                return;
            }
        }
        localStorage.setItem('iframe-message-timestamp', new Date().getTime().toString());
        handleTrx(message);
    }
    return (
        <div>
            <PostMessageListener onMessageReceived={(origin, message) => handleEventMessage(origin, message)} />
            {isLoading && <LoadingSpinner/>}
            <Header/>
            <StepNavigation currentStep={3} />
            {membershipApplication?.isAlternatePayerAllowed && membershipApplication?.authorizerNotified === null &&
                <Card>
                    <div className={"row"}>
                        <div className={"col-md-6 col-sm-12"}>
                            <input type={"radio"} checked={isProspectAuthorizerValue} onChange={() => setIsProspectAuthorizerValue(true)}/>
                            <span className={"margin-left-10"}>I will provide payment details and sign</span>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <input type={"radio"} checked={!isProspectAuthorizerValue} onChange={() => setIsProspectAuthorizerValue(false)}/>
                            <span className={"margin-left-10"}>Send to a colleague for payment and signature</span>
                        </div>
                    </div>
                </Card>
            }
            {isProspectAuthorizerValue !== undefined && !isProspectAuthorizerValue && membershipApplication?.authorizerNotified === null &&
                <Fragment>
                    <AuthorizedSignerForm
                        name={authorizerNameValue}
                        title={authorizerTitleValue}
                        email={authorizerEmailValue}
                        sendEmail={sendAuthorizerEmailHandler}
                    />
                </Fragment>
            }
            {/*TODO:update the conditions below, missing 1*/}
            {membershipApplication && (!membershipApplication.isAlternatePayerAllowed
                    || (membershipApplication.isAlternatePayerAllowed && isProspectAuthorizerValue)
                ) &&
                <Fragment>
                    {(formHasErrors || showDataLoadError) &&
                        <div className={"form-error margin-top-20"}>{formError}</div>
                    }
                    <h3 className="form-heading margin-top-20">Recurring payment information</h3>
                    <Card>
                        <Input
                            label={"Billing Start Month"}
                            type={"text"}
                            required={true}
                            readOnly={true}
                            value={billingStartMonthText || ''}
                            styles={""}
                            onChange={() => {}}
                        />
                        <Select
                            label={"Method of Payment"}
                            required={true}
                            readOnly={false}
                            value={methodOfPaymentValue}
                            options={paymentMethods}
                            styles={formHasErrors && !methodOfPaymentValue ? "select-has-error" : ""}
                            onChange={methodOfPaymentValueChangeHandler}
                        />
                        <Select
                            label={"Billing Frequency"}
                            required={true}
                            readOnly={false}
                            value={billingFrequencyValue}
                            options={frequencyOptions}
                            styles={formHasErrors && !billingFrequencyValue ? "select-has-error" : ""}
                            onChange={billingFrequencyValueChangeHandler}
                        />
                        {methodOfPaymentValue && billingFrequencyValue && methodOfPaymentValue === 'EFT' &&
                            <Fragment>
                                <Input
                                    label={"Financial Institution"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={financialInstitutionValue}
                                    styles={formHasErrors && financialInstitutionValueHasError ? "input-has-error" : ""}
                                    onChange={financialInstitutionValueChangeHandler}
                                    maxLength={255}
                                />
                                <Input
                                    label={"ABA/Routing Number"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={routingNumberValue}
                                    styles={formHasErrors && routingNumberValueHasError ? "input-has-error" : ""}
                                    onChange={routingNumberValueChangeHandler}
                                    minLength={9}
                                    maxLength={9}
                                />
                                <Input
                                    label={"Account number"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={accountNumberValue}
                                    styles={formHasErrors && accountNumberValueHasError ? "input-has-error" : ""}
                                    onChange={accountNumberValueChangeHandler}
                                    maxLength={20}
                                />
                                <EFTHelpText/>
                            </Fragment>
                        }
                        {methodOfPaymentValue && billingFrequencyValue && (methodOfPaymentValue === "CC" || methodOfPaymentValue === "INV")  &&
                            <Fragment>
                                <div className={"row"}>
                                    <div className={"col-md-12 col-sm-12"}>
                                        <b>Billing Address</b>
                                    </div>
                                </div>
                                <Input
                                    label={"Company Name"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={companyNameValue}
                                    styles={formHasErrors && companyNameValueHasError ? "input-has-error" : ""}
                                    onChange={companyNameValueChangeHandler}
                                />
                                <Input
                                    label={"Address 1"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={address1Value}
                                    styles={formHasErrors && address1ValueHasError ? "input-has-error" : ""}
                                    onChange={address1ValueChangeHandler}
                                    maxLength={255}
                                />

                                <Input
                                    label={"Address 2"}
                                    type={"text"}
                                    required={false}
                                    readOnly={false}
                                    value={address2Value}
                                    styles={""}
                                    onChange={address2ValueChangeHandler}
                                    maxLength={100}
                                />

                                <Input
                                    label={"City"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={cityValue}
                                    styles={formHasErrors && cityValueHasError ? "input-has-error" : ""}
                                    onChange={cityValueChangeHandler}
                                    maxLength={40}
                                />

                                <Select
                                    label={"State"}
                                    required={true}
                                    readOnly={false}
                                    value={selectedState}
                                    options={statesOptions}
                                    styles={formHasErrors && !selectedState ? "select-has-error" : ""}
                                    onChange={stateValueChangeHandler}
                                />

                                <Input
                                    label={"Zip"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={zipValue}
                                    styles={formHasErrors && zipValueHasError ? "input-has-error" : ""}
                                    onChange={zipValueChangeHandler}
                                    maxLength={20}
                                />

                                <Select
                                    label={"Country"}
                                    required={true}
                                    readOnly={selectedCountry === 'US'}
                                    value={selectedCountry}
                                    options={countriesOptions}
                                    styles={formHasErrors && !selectedCountry ? "select-has-error" : ""}
                                    onChange={countryValueChangeHandler}
                                />
                            </Fragment>}
                        {methodOfPaymentValue && billingFrequencyValue &&
                            <Fragment>
                                <div className={"row margin-top-20"}>
                                    <div className={"col-md-12 col-sm-12"}>
                                        <b>Billing Contact</b>
                                    </div>
                                </div>
                                <Input
                                    label={"Name"}
                                    type={"text"}
                                    required={false}
                                    readOnly={false}
                                    value={billingContactNameValue}
                                    styles={""}
                                    onChange={billingContactNameValueChangeHandler}
                                    maxLength={255}
                                />
                                <Input
                                    label={"Email"}
                                    type={"text"}
                                    required={true}
                                    readOnly={false}
                                    value={billingContactEmailValue}
                                    styles={formHasErrors && billingContactEmailValueHasError ? "input-has-error" : ""}
                                    onChange={billingContactEmailValueChangeHandler}
                                    maxLength={80}
                                />
                                <Input
                                    label={"Phone"}
                                    type={"text"}
                                    required={false}
                                    readOnly={false}
                                    value={billingContactPhoneValue}
                                    styles={""}
                                    onChange={billingContactPhoneValueChangeHandler}
                                    maxLength={40}
                                />
                                {membershipApplication?.groupType === "TA-SAND" &&
                                    <Fragment>
                                        <div className={"margin-top-20 row"}>
                                            <div>Trusted Advisor program only</div>
                                            <div>TA members may receive commission payments for successful member and Chair referrals.
                                                Please choose what form of payment you'd like for these commissions.</div>
                                        </div>
                                        <Select
                                            label={"Thank you Payment Type"}
                                            required={true}
                                            readOnly={false}
                                            value={taThankYouTypeValue}
                                            options={taPaymentTypes}
                                            styles={formHasErrors && !taThankYouTypeValue ? "select-has-error" : ""}
                                            onChange={taThankYouTypeValueChangeHandler}
                                        />
                                    </Fragment>
                                }
                                <AmountDue
                                    membershipApplication={membershipApplication}
                                    billingFrequencyValue={billingFrequencyValue}
                                    currentlySelectedBillingFrequency={currentlySelectedBillingFrequency}
                                    methodOfPaymentValue={methodOfPaymentValue}
                                    processingFee={processingFee}
                                />
                                <div className={"row margin-top-20"}>
                                    <div className={"col-md-12 col-sm-12"}>
                                        <h4>Membership and financial agreement</h4>
                                    </div>
                                    <div className={"col-md-12 col-sm-12"} dangerouslySetInnerHTML={{__html: membershipApplication?.agreement.financialAgreement || ''}}>
                                    </div>
                                </div>

                                <div className={"row margin-top-20 terms-acceptance-checkbox-row"}>
                                    <label>
                                        <input type={"checkbox"} checked={isTermsAcceptedValue} onChange={isTermsAcceptedValueChangeHandler} className={formHasErrors && !isTermsAcceptedValue ? "input-has-error" : ""}/>
                                        {membershipApplication?.groupType !== 'EL-SAND' &&
                                            <span>I accept the <IFrameLink textToDisplay={"Membership Terms and Conditions Agreement"} heading={"Membership Terms and Conditions Agreement"} url={membershipApplication?.agreement.termsLink} />, including the 90-day notice requirement to cancel my membership.</span>
                                        }
                                        {membershipApplication?.groupType === 'EL-SAND' &&
                                            <span>I accept the <IFrameLink textToDisplay={"Membership Terms and Conditions Agreement"} heading={"Membership Terms and Conditions Agreement"} url={membershipApplication?.agreement.termsLink} />, including the 60-day written notice requirement to cancel my membership prior to my second year of membership.</span>
                                        }
                                    </label>
                                </div>

                                <div className={"row margin-top-20 terms-acceptance-name-row"}>
                                    <div className={"col-md-7 col-sm-12"}>
                                        <input
                                            type={"text"}
                                            className={`input-textbox ${formHasErrors && !isNotEmpty(termsAcceptedNameValue) ? "input-has-error" : ""}`}
                                            value={termsAcceptedNameValue}
                                            onChange={termsAcceptedNameValueChangeHandler}
                                            placeholder={"Please enter your name"}
                                        />
                                    </div>
                                    <div className={"col-md-5 col-sm-12 font-weight-bold"}>
                                        Submitted {today.toString()}
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Card>
                </Fragment>
            }
            {/*TODO:update the conditions below, missing 1*/}
            {membershipApplication && (!membershipApplication.isAlternatePayerAllowed
                    || (membershipApplication.isAlternatePayerAllowed && isProspectAuthorizerValue)
                ) &&
                <div className="margin-top-20 nav-buttons-row">
                    <SubmitButton text={methodOfPaymentValue === "CC" ? "Proceed to Payment and Submit Application" : "Submit Application"} onClickEventHandler={saveApplication} />
                    <NavigationButton text={"Back"} onClickEventHandler={goBack} icon={faAngleLeft} iconPlacement={"left"}/>
                </div>
            }
            <Footer version={membershipApplication?.agreement.version || ''}/>
            {showPayFabricIFrame && payFabricIframeUrl &&
                <IFrame heading={"Recurring Payment Information"} url={payFabricIframeUrl} isPayFabricWindow={true} onClose={() => {}}/>
            }
        </div>
    );
}

export default PaymentInformation;
