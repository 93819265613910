import {MembershipApplication} from "../models/MembershipApplication";
import {Context, createContext, FC, PropsWithChildren, useCallback, useState} from "react";
import useHttp from "../hooks/use-http";

type MembershipApplicationContextObj = {
    membershipApplication: MembershipApplication | undefined,
    // originalMembershipApplication: MembershipApplication | undefined,
    getMembershipApplication: (token: string | undefined) => void,
    ipAddress: string | undefined,
    getIpAddress: (token: string | undefined) => void,
    updateMembershipApplication: (application: MembershipApplication) => void,
    // updateOriginalMembershipApplication: (application: MembershipApplication) => void,
    isLoading: boolean,
    statusCode: string | null,
    error: any
};

const MembershipApplicationContext: Context<MembershipApplicationContextObj> = createContext<MembershipApplicationContextObj>({
    membershipApplication: undefined,
    // originalMembershipApplication: undefined,
    getMembershipApplication: (token: string | undefined) => {},
    ipAddress: undefined,
    getIpAddress: (token: string | undefined) => {},
    updateMembershipApplication: (application: MembershipApplication) => {},
    // updateOriginalMembershipApplication: (application: MembershipApplication) => {},
    isLoading: false,
    statusCode: '',
    error: null
});

export const MembershipApplicationContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [membershipApplication, setMembershipApplication] = useState<MembershipApplication>();
    // const [originalMembershipApplication, setOriginalMembershipApplication] = useState<MembershipApplication>();
    const [ipAddress, setIpAddress] = useState(undefined);
    const { isLoading, statusCode, sendRequest, error } = useHttp();
    const getMembershipApplicationHandler = useCallback((token: string | undefined) => {
        const handleGetMembershipApplicationResponse = (response: any) => {
            setMembershipApplication(response);
            // setOriginalMembershipApplication(response);
        }
        sendRequest({
            url: '/membership/validate/' + token,
            token: token
        }, handleGetMembershipApplicationResponse, () => {});
        return
    }, [sendRequest]);
    const getIpAddressHandler = useCallback((token: string | undefined) => {
        const handleGetIpAddressResponse = (response: any) => {
            setIpAddress(response.address);
        }
        sendRequest({
            url: '/ip',
            token: token
        }, handleGetIpAddressResponse, () => {});
    }, [sendRequest]);
    const updateMembershipApplicationHandler = useCallback((application: MembershipApplication) => {
        setMembershipApplication(application);
    }, []);
    // const updateOriginalMembershipApplicationHandler = useCallback((application: MembershipApplication) => {
    //     setOriginalMembershipApplication(application);
    // }, []);
    const context = {
        membershipApplication: membershipApplication,
        // originalMembershipApplication: originalMembershipApplication,
        getMembershipApplication: getMembershipApplicationHandler,
        ipAddress: ipAddress,
        getIpAddress: getIpAddressHandler,
        updateMembershipApplication: updateMembershipApplicationHandler,
        // updateOriginalMembershipApplication: updateOriginalMembershipApplicationHandler,
        isLoading: isLoading,
        statusCode: statusCode,
        error: error
    }
    return(
        <MembershipApplicationContext.Provider value={context}>
            {children}
        </MembershipApplicationContext.Provider>
    )
}
export default MembershipApplicationContext;
