import {Context, createContext, FC, PropsWithChildren, useCallback, useState} from "react";

export type AppConfig = {
    currentEnvironment: string,
    totalMemberCount: string,
    emailJSUrl: string,
    payFabricBaseUrl: string,
    teamsAlertsUrl: string,
    emailJSApplicationUpdateFailedTemplate: string,
    emailJSApplicationPostToGpFailedTemplate: string,
    emailJSUserId: string,
    emailJSServiceId: string,
    memberStatementUrl: string
}
type AppContextObj = {
    config: AppConfig | undefined,
    setAppContext: () => void
}
const AppContext: Context<AppContextObj> = createContext<AppContextObj>({
    config: undefined,
    setAppContext: () => {}
});

export const AppContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [config, setConfig] = useState<AppConfig>();
    const setAppContextHandler = useCallback(async () => {
        const response = await fetch(window.location.origin + '/config.json');
        const data = await response.json();
        setConfig(data);
    }, []);
    const context = {
        config: config,
        setAppContext: setAppContextHandler
    }
    return (
        <AppContext.Provider value={context}>
            {children}
        </AppContext.Provider>
    );
}

export default AppContext;

