import React, {FC} from "react";
import Content from "./Content";
import classes from "./Container.module.css";

const Container: FC = () => {
    return (
        <div className={classes.container}>
            <Content />
        </div>
    )
}

export default Container;
