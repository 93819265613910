import {ChangeEventHandler, FC} from "react";
import classes from "./Input.module.css";

type InputObj = {
    label: string;
    type: string;
    helpText?: string;
    required: boolean;
    readOnly: boolean;
    value: string;
    styles: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    maxLength?: number;
    minLength?: number;
    overrideColWidth?: string;
};

const Input: FC<InputObj> = ({label, type, helpText, required, readOnly, value, styles, onChange, overrideColWidth, ...props}) => {
    return (
        <div className="row">
            <div className="col-md-3 col-sm-6 col-sm-12">
                <label
                    className={`${required ? classes['input-required'] : ''}`}
                    htmlFor={label}
                >
                    {label}
                </label>
            </div>
            <div className={`${overrideColWidth ? overrideColWidth : 'col-md-9'}`}>
                <input
                    className={`${classes['input-textbox']} 
                        ${styles ? classes[styles] : ''} 
                        ${readOnly ? classes['input-readonly'] : ''}`
                    }
                    type={type}
                    value={value}
                    readOnly={readOnly}
                    onChange={onChange}
                    {...props}
                />
                {helpText &&
                    <span className={classes["input-help-text"]}><i>{helpText}</i></span>
                }
            </div>
        </div>
    );
};

export default Input;
