import {createContext, FC, ReactNode, useContext, useState} from "react";
import MessageBox from "../components/MessageBox/MessageBox";

interface MessageBoxAction {
    onClick: () => void;
}

interface MessageBoxContextType {
    showMessage: (
        title: string,
        message: string,
        action?: MessageBoxAction
    ) => void;
}

const MessageBoxContext = createContext<MessageBoxContextType | undefined>(undefined);

export const useMessageBox = () => {
    const context = useContext(MessageBoxContext);
    if (!context) {
        throw new Error("useMessageBox must be used within a MessageBoxProvider");
    }
    return context;
};

export const MessageBoxProvider: FC<{ children: ReactNode }> = ({children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [title, setTitle] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [action, setAction] = useState<MessageBoxAction | undefined>(undefined);

    const showMessage = (
        msgTitle: string,
        msgBody: string,
        msgAction?: MessageBoxAction
    ) => {
        setTitle(msgTitle);
        setMessage(msgBody);
        setAction(() => msgAction ? {
            onClick: () => {
                msgAction.onClick();
                closeMessageBox(); // Close the message box after action
            }
        } : undefined);
        setIsVisible(true);
    };

    const closeMessageBox = () => {
        setIsVisible(false);
    };

    return (
        <MessageBoxContext.Provider value={{showMessage}}>
            {children}
            {isVisible && (
                <MessageBox title={title} message={message}
                            buttonClickHandler={action ? action.onClick : closeMessageBox}/>
            )}
        </MessageBoxContext.Provider>
    );
};
