import {FC, MouseEventHandler} from "react";
import classes from "./NavigationButton.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NavigationButton: FC<{text: string, onClickEventHandler: MouseEventHandler, icon: any, iconPlacement: string}> = ({text, onClickEventHandler, icon, iconPlacement}) => {
    return (
        <button className={classes['navigation-button']} onClick={onClickEventHandler}>
            {iconPlacement === 'left' && <span><FontAwesomeIcon icon={icon}/></span>}
            {text}
            {iconPlacement === 'right' && <span><FontAwesomeIcon icon={icon}/></span>}
        </button>
    )
}
export default NavigationButton;
