import {FC} from "react";
import classes from "./Footer.module.css";

const Footer: FC<{version: string}> = ({version}) => {
    const currentYear = new Date();
    return (
        <div className={`row ${classes['footer-row']}`}>
            <div className={classes.footer}>
                <div><b>© {currentYear.getFullYear()} Vistage Worldwide, Inc.</b></div>
                <div><b>{version}</b></div>
            </div>
        </div>
    )
}
export default Footer;
