import classes from "./Select.module.css"
import {ChangeEventHandler, FC} from "react";

type Option = {
    display: string | number;
    value: string | number;
}

type SelectObj = {
    label: string;
    helpText?: string;
    required: boolean;
    readOnly: boolean;
    value: any;
    options: Option[];
    styles: string;
    onChange: ChangeEventHandler;
    overrideColWidth?: string;
};
const Select: FC<SelectObj> = ({label, helpText, required, readOnly, value, options, styles, onChange, overrideColWidth}) => {
    return (
        <div className="row">
            <div className="col-md-3 col-sm-6 col-sm-12">
                <label
                    className={`${required ? classes['select-required'] : ''}`}
                    htmlFor={label}
                >
                    {label}
                </label>
            </div>
            <div className={`${overrideColWidth ? overrideColWidth : 'col-md-9'}`}>
                <select value={value} onChange={onChange} disabled={readOnly} className={classes[styles]}>
                    <option value={""}>-- Select --</option>
                    {options.map((option: Option) => (
                        <option value={option.value} key={option.value}>{option.display}</option>
                    ))}
                </select>
                {helpText &&
                    <span className={classes["select-help-text"]}><i>{helpText}</i></span>
                }
            </div>
        </div>
    );
}

export default Select;
