import React, {FC, Fragment} from "react";
import {Navigate, Routes, Route} from 'react-router-dom';
import MembershipCommitments from "../pages/MembershipCommitments/MembershipCommitments";
import ExpiredLink from "../pages/ExpiredLink/ExpiredLink";
import TokenValidationGuard from "../guards/TokenValidationGuard";
import {MembershipApplicationContextProvider} from "../store/membership-application-context";
import MemberInformation from "../pages/MemberInformation/MemberInformation";
import PaymentInformation from "../pages/PaymentInformation/PaymentInformation";
import classes from "./Content.module.css";
import ThankYou from "../pages/ThankYou/ThankYou";
import ProformaInvoice from "../pages/ProformaInvoice/ProformaInvoice";
import PaymentResponse from "../pages/PaymentResponse/PaymentResponse";

const Content: FC = () => {
    return (
        <div className={classes.content}>
            <Routes>
                <Route path="/:token/*" element={
                    <MembershipApplicationContextProvider>
                        <TokenValidationGuard>
                            <Routes>
                                <Route path="/membership-commitments" element={ <MembershipCommitments/> } />
                                <Route path="/member-information" element={ <MemberInformation /> } />
                                <Route path="/payment-information" element={ <PaymentInformation /> } />
                            </Routes>
                        </TokenValidationGuard>
                    </MembershipApplicationContextProvider>
                }>
                </Route>
                <Route path="/payment-response" element={ <PaymentResponse /> } />
                <Route path="/thank-you" element={ <ThankYou /> } />
                <Route path="/proforma" element={ <ProformaInvoice /> } />
                <Route path="/expired-link" element={ <ExpiredLink/> } />
                <Route path="/" element={
                    <Fragment>
                        <Navigate to="/expired-link"/>
                    </Fragment>
                } />
            </Routes>
        </div>
    )
}

export default Content;
