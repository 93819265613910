import React, {FC, useState} from "react";
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Header from "../../components/Header/Header";
import useHttp from "../../hooks/use-http";
import Card from "../../components/Card/Card";
import {getInvoiceEndpoint} from "../../utils/Constants";

const ThankYou: FC = () => {
    const {isLoading, sendRequest} = useHttp();
    const [searchParams] = useSearchParams();
    const isInv = searchParams.get("isInv");
    const sentForApprover = searchParams.get("forApprover");
    const token = searchParams.get("token");
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState("");
    const downloadProformaInvoice = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setError("");
        setHasError(false);
        sendRequest({
            method: "GET",
            url: getInvoiceEndpoint(token!),
            token: token,
            withPdf: true
        }, (response: any) => {
            const invoicePdfBlob = new Blob([response], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(invoicePdfBlob);
            link.download = token + "_" + new Date().getTime() + ".pdf";
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 0);
        }, (error: any) => {
            setHasError(true);
            setError("An error occurred while downloading invoice, please try again. Contact Vistage if the issue persists.");
        })
    }
    return (
        <div>
            {isLoading && <LoadingSpinner/>}
            <Header/>
            {(hasError) &&
                <div className={"form-error"}>{error}</div>
            }
            <h3 className="form-heading margin-top-20">Thank You</h3>
            <Card>
                <div className={"row"}>
                    <div className="col-md-12">
                        {sentForApprover && "Your Request has been sent."}
                        {!sentForApprover && "Thank you for your membership. We will email you a receipt for your records."}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        For questions regarding your application, please contact the Vistage Client Services team
                        Monday - Friday, 7:00 AM to 5:00 PM PT.
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        Toll Free: 800.274.2367 <br/>
                        Email: clientservicesteam@vistage.com <br/>
                        Website: vistage.com
                    </div>
                </div>
                {isInv &&
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href={"#"} onClick={downloadProformaInvoice}>A proforma copy of your initial invoice is available here.</a> Please review and remit payment prior to your first group meeting.
                        </div>
                    </div>
                }

            </Card>
        </div>
    );
}
export default ThankYou;
