import {FC, useContext, useEffect} from "react";
import AppContext from "../../store/app-context";
import {payFabricCustomMessages} from "../../utils/Constants";

interface PostMessageListenerProps {
    onMessageReceived: (origin: string, message: any) => void;
}
// PostMessageListener is specifically built for step 3 only
const PostMessageListener: FC<PostMessageListenerProps> = ({onMessageReceived}) => {
    const {config} = useContext(AppContext);
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== config?.payFabricBaseUrl && event.origin !== window.location.origin) {
                // url mismatch
                return;
            }
            if (typeof event.data === "string" && !payFabricCustomMessages.includes(event.data)) {
                // unnecessary string messages from browser extensions
                return;
            }
            if (typeof event.data === "object" && !event.data.trxKey && !event.data.resultCode && !('trxSuccessful' in event.data)) {
                // ignore messages that are not the expected transaction objects
                return;
            }
            onMessageReceived(event.origin, event.data);
        };
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [config?.payFabricBaseUrl, onMessageReceived]);
    return null;
}
export default PostMessageListener;
