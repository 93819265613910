import React, {FC, Fragment, useContext, useEffect, useState} from "react";
import useHttp from "../../hooks/use-http";
import {useSearchParams} from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Header from "../../components/Header/Header";
import Card from "../../components/Card/Card";
import {getInvoiceEndpoint, getPdfBlobAnchorElement} from "../../utils/Constants";
import AppContext from "../../store/app-context";

const ProformaInvoice: FC = () => {
    const {isLoading, sendRequest} = useHttp();
    const {config} = useContext(AppContext);
    const [errorLoadingPdf, setErrorLoadingPdf] = useState<boolean>(false);
    const [downloadLink, setDownloadLink] = useState<HTMLAnchorElement | null>(null);
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [counter, setCounter] = useState<number | null>(null);
    useEffect(() => {
        if (token) {
            sendRequest({
                method: "GET",
                url: getInvoiceEndpoint(token!),
                token: token!,
                withPdf: true
            }, (response: any) => {
                if (response) {
                    setDownloadLink(getPdfBlobAnchorElement(token!, response));
                    setCounter(5);
                } else  {
                    setErrorLoadingPdf(true);
                }
            }, () => {
                setErrorLoadingPdf(true);
            })
        }
    }, [sendRequest, token]);
    useEffect(() => {
        if (counter === null || counter <= 0) {
            if (counter === 0 && downloadLink) {
                triggerDownload(downloadLink);
            }
            return;
        }
        const timer = setInterval(() => {
            setCounter((prev) => (prev !== null ? prev - 1 : null));
        }, 1000);

        return () => clearInterval(timer);
    }, [counter, downloadLink]);
    const triggerDownload = (link: HTMLAnchorElement) => {
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            {isLoading && <LoadingSpinner/>}
            {!isLoading && !errorLoadingPdf && downloadLink &&
                <Fragment>
                    <Header/>
                    <Card>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                Your proforma invoice will be downloaded in {counter} seconds. If it doesn't, please click <a href={"#"} onClick={() => triggerDownload(downloadLink)}>here</a>.
                            </div>
                        </div>
                    </Card>
                </Fragment>
            }
            {!isLoading && errorLoadingPdf &&
                <Fragment>
                    <Header/>
                    <Card>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                This proforma invoice is no longer available. Your most recent account and payment information is available through your <a href={config?.memberStatementUrl} >Member Statement</a>.
                            </div>
                        </div>
                    </Card>
                </Fragment>
            }
        </div>
    );
}

export default ProformaInvoice;
