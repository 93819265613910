import {FC} from "react";
import Header from "../../components/Header/Header";
import Card from "../../components/Card/Card";

const ExpiredLink: FC = () => {
    return (
        <div>
            <Header/>
            <Card>
                <h4 className="margin-0 form-heading">This link has expired</h4>
                <p>
                    The membership application for this link is no longer available. For assistance, please contact the
                    Vistage Client Services team at 800.274.2367 or clientservicesteam@vistage.com
                </p>
            </Card>
        </div>
    )
}
export default ExpiredLink;
