export class CreateTransactionRequest {
    customer: string;
    currency: string = 'USD';
    amount: number;
    type: string = 'Sale';
    setupId: string = 'Cybersource';

    constructor(customer: string,
                amount: number,
                isDelayedPayment: boolean = false,
                isProductionEnvironment: boolean = false) {
        this.customer = customer.startsWith('00000') ? ('MEMAPP' + customer) : customer;
        this.type = isDelayedPayment ? 'Book' : 'Sale';
        this.amount = isDelayedPayment ? 1 : (isProductionEnvironment ? amount : (amount > 999 ? 999 : amount));
    }
}
