import {FC, Fragment, PropsWithChildren} from "react";
import classes from './Modal.module.css';
import {createPortal} from "react-dom";

const Backdrop: FC = () => {
    return <div className={classes.backdrop} />;
};

const ModalOverlay: FC<PropsWithChildren> = ({children}) => {
    return (
        <div className={classes['modal-overlay']}>{children}</div>
    );
};

const portalElement = document.getElementById('overlay')!;

const Modal: FC<PropsWithChildren> = ({children}) => {
    return (
        <Fragment>
            {createPortal(<Backdrop />, portalElement)}
            {createPortal(
                <ModalOverlay>{children}</ModalOverlay>,
                portalElement
            )}
        </Fragment>
    );
};

export default Modal;
